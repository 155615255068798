<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <div v-if="showConfirmDialog" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="postClosingTradeToTDA">
          <div v-for="(port, i) in Portfolios.port" :key="i">
            <p>
              {{ port.sp }} | {{ port.cps }} | {{ port.xp }} |
              {{ port.up.toFixed(2) }}
            </p>
          </div>
          <br />
          <br />
          <h4>Please enter the net debit expected</h4>
          <input v-model="netDebit" id="netDebit" class="T4" />
          <br />
          <br />
          <base-button type="submit">Post</base-button>
          <base-button type="button" @click.native="closeConfirmDialog"
            >Close</base-button
          >
        </form>
      </div>
    </div>
    <section>
      <base-card>
        <div class="controls">
          <base-button mode="outline" link to="/addportfolio">Add</base-button>
          <base-button @click.native="portfolioRefresh">Refresh</base-button>
        </div>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <table v-if="hasPortfolios" class="table">
          <th>Name</th>
          <th>CB</th>
          <th>$-Exp</th>
          <th>Updated</th>
          <th>Active</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <port-item
            v-for="port in Portfolios"
            :key="port.tradename"
            :tradename="port.tradename"
            :net="port.net"
            :totalExposure="port.totalExposure"
            :updated="port.updated"
            :active="port.active"
            @closeport="closePort"
            @delport="delPort"
            @postClosing2TDA="openConfirmDialog"
          ></port-item>
        </table>
        <h3 v-if="!hasPortfolios">No portfolio found.</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import PortItem from "./PortItem.vue";
import myMixins from "../mixins/CreateOrder.js";

export default {
  components: {
    PortItem,
  },
  mixins: [myMixins],
  data() {
    return {
      netDebit: 0,
      openPosition: {},
      newPort: [],
      isLoading: false,
      error: null,
      showConfirmDialogFlag: false,
    };
  },
  computed: {
    Portfolios() {
      var portList = this.$store.getters["portfolio/getAllPortfolios"];
      portList.sort((a, b) => {
        if (a.active > b.active) {
          return -1;
        } else {
          return +1;
        }
      });

      portList.forEach((ele) => {
        var net = 0;
        var longXp = 0;
        var shortXp = 0;
        ele.port.forEach((p) => {
          if (p.oc == "O") {
            if (p.xp < 0) {
              shortXp += p.xp * -1;
            } else {
              longXp += p.xp * 1;
            }
          } else {
            if (p.xp < 0) {
              longXp -= p.xp * -1;
            } else {
              shortXp -= p.xp * 1;
            }
          }

          net += Number(p.up) * Number(p.xp);
        });

        var netXp = 0;
        if (longXp > shortXp) {
          netXp = longXp - shortXp;
        } else if (longXp < shortXp) {
          netXp = shortXp - longXp;
        } else {
          netXp = longXp;
        }

        ele.totalExposure = net;
        if (netXp > 0) {
          ele.net = net / netXp;
        } else {
          ele.net = net;
        }
      });

      console.log(portList);
      return portList;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    hasPortfolios() {
      return this.$store.getters["portfolio/hasPortfolios"];
    },
    showConfirmDialog() {
      return this.showConfirmDialogFlag;
    },
  },
  created() {
    this.loadPortfolios();
  },
  methods: {
    async postClosingTradeToTDA() {
      this.showConfirmDialogFlag = false;
      this.isLoading = true;

      var token = this.$store.getters.tdatoken;
      var tdacct = this.$store.getters.tdacct;
      if (tdacct !== null) {
        await this.createOrder(
          token,
          tdacct,
          { port: this.newPort },
          this.netDebit
        );
        console.log("Created order on TDA - Yayyy!!");
      }
      this.isLoading = false;
    },
    async openConfirmDialog(obj) {
      this.isLoading = true;
      if (obj.tradename !== "") {
        var port = this.$store.getters["portfolio/getAllPortfolios"].find(
          (port) => port.tradename === obj.tradename
        );
        this.openPosition = port;
      }

      this.newPort = [];
      this.openPosition.port.forEach((ele) => {
        this.newPort.push({ xp: ele.xp * -1, symbol: ele.symbol });
      });
      this.isLoading = false;
      this.openPosition.port.forEach((ele) => {
        if (ele.oc === "C") {
          this.error = "This position has already been closed!!";
          return;
        }
      });

      if (this.error != null) {
        return;
      }

      this.showConfirmDialogFlag = true;
    },
    closeConfirmDialog() {
      this.showConfirmDialogFlag = false;
    },
    async closePort(obj) {
      obj.active = "N";
      await this.updatePort(obj);
    },
    async delPort(obj) {
      obj.active = "D";
      await this.updatePort(obj);
    },
    async updatePort(obj) {
      var payload = {};
      this.isLoading = true;
      if (obj.tradename !== "") {
        var port = this.$store.getters["portfolio/getAllPortfolios"].find(
          (port) => port.tradename === obj.tradename
        );
        payload = port;
        payload.active = obj.active;
        try {
          await this.$store.dispatch("portfolio/registerPortfolio", payload);
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
      this.isLoading = false;
    },
    async loadPortfolios() {
      this.isLoading = true;
      if (!this.$store.getters["portfolio/hasPortfolios"]) {
        try {
          await this.$store.dispatch("portfolio/setPortfolios");
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
      this.isLoading = false;
    },
    async portfolioRefresh() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("portfolio/setPortfolios");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.json-popup {
  position: relative;
  text-align: center;
  width: 50%;
  opacity: 1;
}
.form-popup {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-45%, 5%);
  border: 2px solid rgb(100, 24, 24);
  z-index: 9;
  opacity: 1;
  padding: 20px 20px;
  background-color: rgb(233, 241, 234);
}
.form-container {
  opacity: 1;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}

table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  border: 16px ridge(5, 10, 1);
}

th {
  vertical-align: middle;
  border: 1px solid;
  height: 36px;
  font-size: 12px;
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}
</style>