<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-card>
      <p><b>Select your view type</b></p>
      <label for="summary" class="ilb">Summary</label>
      <input
        class="ilb"
        type="radio"
        value="summary"
        id="summary"
        name="viewtype"
        v-model="viewtype"
      />
      <label for="detailed" class="ilb">Detailed</label>
      <input
        class="ilb"
        type="radio"
        value="detailed"
        id="detailed"
        name="viewtype"
        v-model="viewtype"
      />
    </base-card>

    <section>
      <base-card v-if="viewtype === 'detailed'">
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else-if="hasPnLs">
        <base-button mode="outline" @click.native="exportPnL"
            >Export</base-button
          >
          <base-card>
            <p v-bind:class="{ loss: totalpnl < 0, profit: totalpnl >= 0 }">
              Total P/L: {{ totalpnl }}
            </p>
            <p>Total Capital: {{ totalcapital }}</p>
            <p>ROI: {{ roi }}%</p>
          </base-card>
          <table class="table">
            <th>Quarter</th>
            <th>OpenDate</th>
            <th>CloseDate</th>
            <th>Name</th>
            <th>Ticker</th>
            <th>P/L</th>
            <th>Capital</th>
            <th>Margin</th>
            <th>ROI</th>
            <th>Action</th>
            <pnl-item
              v-for="pnl in PnLs"
              :key="pnl.tradename"
              :qtr="pnl.qtr"
              :opendate="pnl.opendate"
              :closedate="pnl.closedate"
              :tradename="pnl.tradename"
              :ticker="pnl.ticker"
              :amount="pnl.amount"
              :capital="pnl.capital"
              :margin="pnl.margin"
            ></pnl-item>
          </table>
        </div>
        <h3 v-else>No P&L found.</h3>
      </base-card>
      <base-card v-if="viewtype === 'summary'">
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else-if="hasPnLs">
          <base-button mode="outline" @click.native="exportPnL"
            >Export</base-button
          >
          <base-card>
            <p v-bind:class="{ loss: totalpnl < 0, profit: totalpnl >= 0 }">
              Total P/L: {{ totalpnl }}
            </p>
            <p>Total Capital: {{ totalcapital }}</p>
            <p>ROI: {{ roi }}%</p>
          </base-card>
          <table class="table">
            <th>Quarter</th>
            <th>CloseDate</th>
            <th>P/L</th>
            <th>Capital</th>
            <th>Margin</th>
            <th>ROI</th>
            <summary-item
              v-for="pnl in SummaryPnLs"
              :key="pnl.closedate"
              :qtr="pnl.qtr"
              :closedate="pnl.closedate"
              :amount="pnl.amount"
              :capital="pnl.capital"
              :margin="pnl.margin"
            ></summary-item>
          </table>
        </div>
        <h3 v-else>No P&L found.</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import PnlItem from "./PnlItem";
import SummaryItem from "./SummaryPnlItem";

export default {
  components: {
    PnlItem,
    SummaryItem,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      viewtype: "",
    };
  },
  computed: {
    PnLs() {
      var pnlList = this.$store.getters["pnl/getAllPnLs"];
      pnlList.sort((a, b) => {
        if (a.closedate > b.closedate) {
          return -1;
        } else {
          return +1;
        }
      });
      return pnlList;      
    },
    SummaryPnLs() {
      var pnlList = this.$store.getters["pnl/getAllPnLs"];
      pnlList.sort((a, b) => {
        if (a.closedate > b.closedate) {
          return -1;
        } else {
          return +1;
        }
      });
      var summaryList = [];
      var prevEle = {};
      var amt = 0;
      var margin = 0;
      var capital = 0;
      for (var ele of pnlList) {
        if (JSON.stringify(prevEle) === "{}") {
          console.log("initialized previous ele");
          prevEle = ele;
        }
        if (prevEle.closedate === ele.closedate) {
          amt = Number(amt) + Number(ele.amount);
          capital = Number(capital) + Number(ele.capital);
          margin = Number(margin) + Number(ele.margin);
        } else {
          summaryList.push({
            amount: amt,
            capital: capital,
            margin: margin,
            qtr: prevEle.qtr,
            closedate: prevEle.closedate,
          });
          amt = ele.amount;
          margin = ele.margin;
          capital = ele.capital;
          prevEle = ele;
        }
      }
      summaryList.push({
        amount: amt,
        capital: capital,
        margin: margin,
        qtr: prevEle.qtr,
        closedate: prevEle.closedate,
      });
      return summaryList;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    hasPnLs() {
      return !this.isLoading && this.$store.getters["pnl/hasPnLs"];
    },
    totalpnl() {
      var pnl = 0;
      var AllPnL = this.$store.getters["pnl/getAllPnLs"];
      AllPnL.forEach((p) => {
        pnl += Number(p.amount);
      });
      return pnl;
    },
    totalcapital() {
      var capital = 0;
      var AllPnL = this.$store.getters["pnl/getAllPnLs"];
      AllPnL.forEach((p) => {
        capital += Number(p.capital) + Number(p.margin);
      });
      return capital;
    },
    roi() {
      return Math.round((this.totalpnl * 100) / this.totalcapital, 2);
    },
  },
  created() {
    this.loadPnLs();
  },
  methods: {
    exportPnL() {
      var pnlList = this.$store.getters["pnl/getAllPnLs"];
      var pnl = [];
      pnlList.forEach((pos) => {
        var p = [
          pos.closedate,
          pos.tradename,
          pos.amount,
          pos.capital,
          pos.margin,
        ];
        pnl.push(p);
      });
      console.log("Exporting P&L!");
      console.log(JSON.stringify(pnl));
    },
    async loadPnLs() {
      this.isLoading = true;
      if (!this.$store.getters["pnl/hasPnLs"]) {
        try {
          await this.$store.dispatch("pnl/setPnLs", {
            qtr: this.$store.getters["misc/getQtr"],
          });
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 16px ridge(5, 10, 1);
}

th {
  vertical-align:middle;
  border: 1px solid;
  height: 36px;
  font-size: 12px;  
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}

p.profit {
  color: green;
  font-weight: bolder;
}
p.loss {
  color: rgb(160, 30, 7);
  font-weight: bolder;
}
input,
label {
  width: 100%;
  border: none;
  text-align: left;
  vertical-align: middle;
}

input.ilb {
  width: 10%;
  border: 1px solid;
  text-align: left;
  margin-bottom: 0.8rem;
  display: inline-block;
}
</style>