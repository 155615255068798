let timer;
let tdatimer;

export default {
    tryLogin(context) {
        const token = localStorage.getItem('token');
        const tokenExpiration = localStorage.getItem('tokenExpiration');

        const expiresIn = +tokenExpiration - new Date().getTime();

        if (expiresIn < 0) {
            return;
        }

        timer = setTimeout(function () {
            context.dispatch('autoLogout');
        }, expiresIn);

        if (token) {
            context.commit('setToken', {
                token: token
            });
        }
    },
    postLogin(context, payload) {

        const token = payload.token;
        const expiresIn = 1000 * 60 * 60;

        const expirationDate = new Date().getTime() + expiresIn;
        localStorage.setItem('token', token);
        localStorage.setItem('tokenExpiration', expirationDate);

        timer = setTimeout(function () {
            context.dispatch('autoLogout');
        }, expiresIn);

        if (token) {
            context.commit('setToken', {
                token: token
            });
        }
    },
    postTDA(context, payload) {

        const tdatoken = payload.tdatoken;
        const tdacct = payload.tdacct;
        const expiresIn = 1000 * 60 * 25;

        const expirationDate = new Date().getTime() + expiresIn;
        tdatimer = setTimeout(function () {
            context.dispatch('autoTDALogout');
        }, expiresIn);

        localStorage.setItem('tdatoken', tdatoken);
        localStorage.setItem('tdatokenExpiration', expirationDate);
        if (tdatoken) {
            context.commit('setTDAToken', {
                tdatoken: tdatoken,
                tdacct: tdacct
            });
        }
    },
    logout(context) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');

        clearTimeout(timer);

        context.commit('setToken', {
            token: null
        });
    },
    autoLogout(context) {
        context.dispatch('logout');
        context.commit('setAutoLogout');
    },
    autoTDALogout(context) {
        localStorage.removeItem('tdatoken');
        localStorage.removeItem('tdatokenExpiration');

        clearTimeout(tdatimer);

        context.commit('setTDAToken', {
            tdatoken: null
        });
    }
};
