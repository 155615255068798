export default {
    getAllPortfolios(state) {
        console.log('##### Getter GetAllPortfolios#####');
        //console.log(state);
        return state.Portfolios;
    },
    getPortfolioByName(state, payload) {
        state.Portfolios.find((port) => {
            if (port.name === payload.name) {
                return port;
            }
        })
    },
    hasPortfolios(state) {
        console.log('In has Portfolios');
        console.log(state.Portfolios);
        return state.Portfolios && state.Portfolios.length > 0;
    },
};