var myMixins = {
    methods: {
        async getQuote(token, ticker, fromDate, toDate) {
            console.log(fromDate);
            const response = await fetch(
                // `https://api.tdameritrade.com/v1/marketdata/chains?symbol=${ticker}&fromDate=${fromDate}&toDate=${toDate}&range=ALL`, 
                `https://api.schwabapi.com/marketdata/v1/chains?symbol=${ticker}&fromDate=${fromDate}&toDate=${toDate}&range=ALL`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            const json = await response.json();

            if (!response.ok) {
                const error = new Error(json.message || 'Failed to fetch!');
                throw error;
            }

            console.log('JSON from TDA');
            console.log(json);
            return json;
        },
        async getFutureQuote(token, ticker, fromDate) {
            console.log(fromDate);
            const response = await fetch(
                // `https://api.tdameritrade.com/v1/marketdata/chains?symbol=${ticker}&fromDate=${fromDate}&range=ALL`, 
                `https://api.schwabapi.com/marketdata/v1/chains?symbol=${ticker}&fromDate=${fromDate}&range=ALL`,
                
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            const json = await response.json();

            if (!response.ok) {
                const error = new Error(json.message || 'Failed to fetch!');
                throw error;
            }

            console.log('JSON from TDA');
            console.log(json);
            return json;
        }        
    },
}

export default myMixins;