<template>
  <tr v-bind:class="{'portrow':true, 'active':(active === 'Y')}">
    <td>{{ tradename }}</td>
    <td>{{ getNet }}</td>
    <td>{{ getTotalExposure }}</td>
    <td>{{ getFormattedDate }}</td>
    <td>{{ active }}</td>
    <td>
      <base-button mode="flat" link :to="portfolioViewLink">View</base-button>
    </td>
    <td><base-button mode="flat" @click.native="postClose2TDA">Post Close Trade</base-button></td>
    <td><base-button mode="flat"  @click.native="closePort">Close</base-button></td>
    <td><base-button mode="flat"  @click.native="delPort">Delete</base-button></td>
  </tr>
</template>

<script>
export default {
  props: ["tradename", "net", "totalExposure","updated", "active", "ticker"],
  emits: ["closeport","delport","postClosing2TDA"],
  computed: {
    getFormattedDate() {
      // var d=  Date(this.erdate)
      return this.updated;
    },
    getTradeName() {
      return this.tradename;
    },
    getActive() {
      return this.active;
    },
    getNet() {
      return (this.net).toFixed(2)
    },
    getTotalExposure() {
      return (this.totalExposure).toFixed(2)
    },
    portfolioViewLink() {
      return "/viewportfolio/" + this.tradename; 
    },
  },
  methods: {
    closePort() {
      this.$emit("closeport",{ tradename: this.tradename})
    },
    delPort() {
      this.$emit("delport",{ tradename: this.tradename})
    },
    postClose2TDA() {
      this.$emit("postClosing2TDA",{ tradename: this.tradename})      
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.5rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
.portrow {
  background: rgb(211, 210, 149);
}
.portrow.active {
 background: rgb(255,255,255);
}
td {
  border: 1px solid;
  border-color: rgba(7, 109, 29, 0.459);
  font-size: 10px;
}
</style>