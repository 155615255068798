export default {
    async loadHvol(context, payload) {

        //console.log(payload);
        const token = context.rootGetters.token;
        
        var list = [];
        payload.forEach((ele) => {
            list.push(ele);
        })
        //console.log(list);
        //list.push(payload);
        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/hvol',
            {
                method: 'PUT',
                "headers": {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({ owner: token, list: list })
            }
        );

        if (!response.ok) {
            const error = new Error('Failed to Update!');
            throw error;
        }

    },
    async getHvol(context, payload) {

        console.log(payload);
        const token = context.rootGetters.token;
        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/hvol',
            {
                method: 'POST',
                "headers": {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({ owner: token,
                ticker:payload.ticker})
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to fetch!');
            throw error;
        }

        console.log(responseData.data.Items[0]);
        if (responseData.data.Items[0] != null) {
            context.commit('setIv', responseData.data.Items[0]);
        }
    },
};
