<template>
    <tr v-bind:class="{'portrow':true, 'active':(amount >= 0)}">
      <td>{{ qtr }}</td>
      <td>{{ getFormattedOpenDate }}</td>
      <td>{{ getFormattedCloseDate }}</td>
      <td>{{ tradename }}</td>
      <td>{{ ticker }}</td>
      <td class="amt">{{ amount }}</td>
      <td>{{ capital }}</td>
      <td>{{ margin }}</td>
      <td>{{ getRoi }}
      <td>
        <base-button mode="flat" link :to="portfolioViewLink">View</base-button>
      </td>
    </tr>
</template>

<script>
export default {
  props: [
    "qtr",
    "opendate",
    "closedate",
    "tradename",
    "ticker",
    "amount",
    "capital",
    "margin",
  ],
  computed: {
    getRoi() {
      var roi = 0;
      roi = Number(this.amount) * 100 / (Number(this.capital) + Number(this.margin));
      return roi.toFixed(2);
    },
    getFormattedOpenDate() {
      // var d=  Date(this.erdate)
      return this.opendate;
    },
    getFormattedCloseDate() {
      // var d=  Date(this.erdate)
      return this.closedate;
    },
    portfolioViewLink() {
      return "/viewportfolio/" + this.tradename;
    },
  },
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.5rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.portrow {
  background: rgb(241, 201, 185);
}
.portrow.active {
 background: rgb(255,255,255);
}

td {
  border: 1px solid;
  border-color: rgba(7, 109, 29, 0.459);
  font-size: 12px;
}
td.amt {
  font-weight: bold;
  font-size: 14px
}
</style>