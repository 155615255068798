var myMixins = {
    methods: {
        async createOrder(token, prospect, net) {
            var orderLegs = [];
            var orderType = null;
            prospect.port.forEach((ele) => {
                var posType = null;
                if (net > 0) {
                    if (ele.xp < 0) {
                        posType = "SELL_TO_CLOSE";
                    } else {
                        posType = "BUY_TO_CLOSE";
                    }
                } else {
                    if (ele.xp < 0) {
                        posType = "SELL_TO_OPEN";
                    } else {
                        posType = "BUY_TO_OPEN";
                    }
                }

                orderLegs.push({
                    instrument: {
                        symbol: ele.symbol,
                        assetType: "OPTION"
                    },
                    instruction: posType,
                    quantity: Math.abs(ele.xp / 100)
                })
            })
            if (net < 0) {
                orderType = "NET_CREDIT"
            } else {
                orderType = "NET_DEBIT"
            }
            var order = {
                "orderType": orderType,
                "orderStrategyType": "SINGLE",
                "price": Math.abs(net),
                "orderLegCollection": orderLegs,
                "session": "NORMAL",
                "duration": "DAY",
                "complexOrderStrategyType": "IRON_CONDOR"
            }
            console.log("Create Order:", order);

            //const response = await fetch(`https://api.tdameritrade.com/v1/accounts/${tdacct}/orders`, {
            const response = await fetch(`https://api.schwabapi.com/trader/v1/accounts/6C11AE8FACB84DFC887039F46B69516F22C97B64F12BDBC426AE06A8F3D19497/orders`, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(order)
            });

            console.log("Create Order - DONE!!!");
            console.log(response);
        },
        async createBFOrder(token, prospect, net,tradetype) {
            var orderLegs = [];
            var orderType = null;
            prospect.port.forEach((ele) => {
                var posType = null;
                if (tradetype === "Close") {
                    if (ele.xp < 0) {
                        posType = "SELL_TO_CLOSE";
                    } else {
                        posType = "BUY_TO_CLOSE";
                    }
                } else {
                    if (ele.xp < 0) {
                        posType = "SELL_TO_OPEN";
                    } else {
                        posType = "BUY_TO_OPEN";
                    }
                }

                orderLegs.push({
                    instrument: {
                        symbol: ele.symbol,
                        assetType: "OPTION"
                    },
                    instruction: posType,
                    quantity: Math.abs(ele.xp / 100)
                })
            })
            if (net < 0) {
                orderType = "NET_CREDIT"
            } else {
                orderType = "NET_DEBIT"
            }
            var stratType = "BUTTERFLY";
            if ((Number(prospect.port[1].sp) - Number(prospect.port[0].sp)) != 
                (Number(prospect.port[2].sp) - Number(prospect.port[1].sp))) {
                stratType = "UNBALANCED_BUTTERFLY"
            }

            var order = {
                "orderType": orderType,
                "orderStrategyType": "SINGLE",
                "price": Math.abs(net),
                "orderLegCollection": orderLegs,
                "session": "NORMAL",
                "duration": "DAY",
                "complexOrderStrategyType": stratType
            }
            console.log("Create Order:", order);
            //const response = await fetch(`https://api.tdameritrade.com/v1/accounts/${tdacct}/orders`, {
            const response = await fetch(`https://api.schwabapi.com/trader/v1/accounts/6C11AE8FACB84DFC887039F46B69516F22C97B64F12BDBC426AE06A8F3D19497/orders`, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(order)
            });

            console.log("Create Order - DONE!!!");
            console.log(response);
        },
        async createDSOrder(token, prospect, net) {
            var orderLegs = [];
            var orderType = null;
            prospect.port.forEach((ele) => {
                var posType = null;
                if (net < 0) {
                    if (ele.xp < 0) {
                        posType = "SELL_TO_CLOSE";
                    } else {
                        posType = "BUY_TO_CLOSE";
                    }
                } else {
                    if (ele.xp < 0) {
                        posType = "SELL_TO_OPEN";
                    } else {
                        posType = "BUY_TO_OPEN";
                    }
                }

                orderLegs.push({
                    instrument: {
                        symbol: ele.symbol,
                        assetType: "OPTION"
                    },
                    instruction: posType,
                    quantity: Math.abs(ele.xp / 100)
                })
            })
            if (net < 0) {
                orderType = "NET_CREDIT"
            } else {
                orderType = "NET_DEBIT"
            }
            var order = {
                "orderType": orderType,
                "orderStrategyType": "SINGLE",
                "price": Math.abs(net),
                "orderLegCollection": orderLegs,
                "session": "NORMAL",
                "duration": "DAY",
                "complexOrderStrategyType": "VERTICAL"
            }
            console.log("Create Order:", order);
            //const response = await fetch(`https://api.tdameritrade.com/v1/accounts/${tdacct}/orders`, {
            const response = await fetch(`https://api.schwabapi.com/trader/v1/accounts/6C11AE8FACB84DFC887039F46B69516F22C97B64F12BDBC426AE06A8F3D19497/orders`, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(order)
            });

            console.log("Create Order - DONE!!!");
            console.log(response);

        }
    },
}

export default myMixins;