<template>
  <base-card>
    <form @submit.prevent="submitForm">
      <div class="form-control" :class="{ invalid: !qtr.isValid }">
        <label for="qtr">Quarter</label>
        <input
          type="text"
          id="qtr"
          v-model.trim="qtr.val"
          @blur="clearValidity('qtr')"
        />
        <p v-if="!qtr.isValid">Quarter must not be empty.</p>
      </div>
      <div class="form-control" :class="{ invalid: !ticker.isValid }">
        <label for="ticker">Ticker</label>
        <input
          type="text"
          id="ticker"
          v-model.trim="ticker.val"
          @blur="clearValidity('ticker')"
        />
        <p v-if="!ticker.isValid">Ticker must not be empty.</p>
      </div>
      <div class="form-control" :class="{ invalid: !erdate.isValid }">
        <label for="erdate">Earnings date</label>
        <input
          id="erdate"
          type="date"
          v-model.trim="erdate.val"
          @blur="clearValidity('erdate')"
        />
        <p v-if="!erdate.isValid">Earnings date must not be empty.</p>
      </div>
      <div class="form-control" :class="{ invalid: !ba.isValid }">
        <label for="ba">Before Open/After Close</label>
        <input
          type="text"
          id="ba"
          v-model.trim="ba.val"
          @blur="clearValidity('ba')"
        />
        <p v-if="!ba.isValid">B/A must not be empty.</p>
      </div>
      <base-button>Add</base-button>
    </form>
    <br />
    <base-button link to="/prospect">Return</base-button>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      qtr: {
        val: "",
        isValid: true,
      },
      ticker: {
        val: "",
        isValid: true,
      },
      erdate: {
        val: "",
        isValid: true,
      },
      ba: {
        val: null,
        isValid: true,
      },
      formIsValid: true,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      if (this.qtr.val === "") {
        this.qtr.isValid = false;
        this.formIsValid = false;
      }
      if (this.ticker.val === "") {
        this.ticker.isValid = false;
        this.formIsValid = false;
      }
      if (this.erdate.val === "") {
        this.erdate.isValid = false;
        this.formIsValid = false;
      }
      if (this.ba.val === "") {
        this.ba.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      const tickerList = this.ticker.val.split(",");
      console.log(tickerList);
      tickerList.forEach(async (ele) => {
        var tkr = ele.trim().toUpperCase();
        const formData = {
          qtr: this.qtr.val,
          ticker: tkr,
          erdate: this.erdate.val,
          ba: this.ba.val,
          name: tkr + "-er-" + this.erdate.val,
          status: "Open",
        };
        await this.$store.dispatch("prospect/registerProspect", formData);
      });
      this.ticker.val = "";
      if (this.ba.val === "B") {
        this.ba.val = "A";
      } else {
        this.ba.val = "B";
        var curDate = new Date(this.erdate.val);
        curDate.setDate(curDate.getDate() + 1);
        this.erdate.val = curDate.toISOString().slice(0, 10);
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  margin: 1rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>