<template>
  <div>
    <base-card>
      <input
        class="short"
        type="text"
        id="ticker"
        v-model="ticker"
        placeholder="Enter ticker"
        @change="hvol=0"
      />
      <base-button type="button" @click.native="lookupHVol">Lookup</base-button>
      <p v-if="hvol > 0 && ticker != ''">Volatility: {{ hvol }}</p>
    </base-card>
    <base-card>
      <div v-if="isPosting">
        <base-spinner></base-spinner>
      </div>
      <form @submit.prevent="submitForm">
        <div class="form-control">
          <label for="hvol">Historical Volatilities</label>
          <textarea
            type="text"
            id="hvol"
            v-model.trim="hvoldata"
            rows="20"
            cols="80"
          />
        </div>
        <base-button>Upload</base-button>
      </form>
    </base-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hvoldata: "",
      isPostingFlag: false,
      currentlyPostingTicker: "",
      ticker: null,
      hvol: null,
    };
  },
  computed: {
    isPosting() {
      return this.isPostingFlag;
    },
    currentlyPosting() {
      return this.currentlyPostingTicker;
    },
  },
  methods: {
    async lookupHVol() {
      this.isPostingFlag = true;
      this.hvol = 0;
      this.$store.getters["hvol/getHvol"].forEach((ele) => {
        console.log("Ele HVOL form:",ele);
        if (ele.ticker === this.ticker.toUpperCase()) {
          this.hvol = ele.hv100;
        }
      });
      if (this.hvol === 0) {
        await this.$store.dispatch("hvol/getHvol", {
          ticker: this.ticker.toUpperCase(),
        });
        this.$store.getters["hvol/getHvol"].find((ele) => {
          if (ele.ticker === this.ticker.toUpperCase()) {
            this.hvol = ele.hv100;
          }
        });
      }
      this.isPostingFlag = false;
    },
    async submitForm() {
      this.isPostingFlag = true;
      const hvolsFileSplit = this.hvoldata.split("\n");
      var hvolArr = [];

      var cleanHvolRaw = hvolsFileSplit.filter((elem) => {
        if (elem.match(/^\*/)) {
          return false;
        } else {
          return true;
        }
      });

      cleanHvolRaw.forEach((element) => {
        var elementsSep = element.match(/\S+/g);
        hvolArr.push({
          ticker: elementsSep[0],
          curiv: elementsSep[5],
          hv100: elementsSep[3],
          hv50: elementsSep[2],
          hv20: elementsSep[1],
          hvr: elementsSep[7].replace("%ile", ""),
        });
      });

      console.log("Total vols:", hvolArr.length);
      var iter = hvolArr.length / 10;
      console.log("Iters:", iter);

      var payload = [];
      for (var ii = 0; ii < iter; ii = ii + 1) {
        payload.push(hvolArr.slice(ii * 10, ii * 10 + 10));
      }
      this.postHvol(payload, 0, iter);
    },
    async postHvol(payload, ind, iter) {
      console.log("Index:", ind);
      console.log("Payload:", payload[ind]);
      await this.$store.dispatch("hvol/loadHvol", payload[ind]);
      if (ind + 1 < iter) {
        await this.postHvol(payload, ind + 1, iter);
      } else {
        this.isPostingFlag = false;
        return;
      }
    },
  },
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input.short {
  width: 50%;
  display: inline;
  margin-right: 20px;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>