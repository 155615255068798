<template>
  <div>
    <br />
    <div class="chart">
      <line-chart v-if="DispChart" :chartdata="chartdata" :options="options" />
    </div>
    <br /><br />
    <div class="tableOuter">
    <table v-if="DispChart">
      <tr>
        <th></th>
        <th v-for="prc in PriceRange" :key="prc">{{ prc }}</th>
      </tr>
      <tr v-for="(row, ind) in DataTable" :key="ind">
        <td>{{ ind + 1 }}</td>
        <td v-for="(col, ndx) in row" :key="ndx">
          <p class="gp" v-if="col.pnl >= 0">P&L: {{ col.pnl }}</p>
          <p class="rp" v-if="col.pnl < 0">P&L: {{ col.pnl }}</p>
          <p>Val: {{ col.totalValue }}</p>
          <p>Del: {{ col.totalDelta }}</p>
        </td>
      </tr>
    </table>
    </div>
  </div>
</template>

<script>
import myOptionCalcMixins from "../mixins/OptionCalculator.js";
import LineChart from "./ui/Chart.vue";

export default {
  props: ["positions", "hvol", "fromPrice", "toPrice","step","minToExp","iv"],
  mixins: [myOptionCalcMixins],
  components: { LineChart },
  data() {
    return {
      dispChartFlag: false,
      datatable: [],
      priceFrom: 0,
      priceTo: 0,
      daysFrom: 0,
      daysTo: 0,
      chartdata: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    DataTable() {
      return this.datatable;
    },
    DispChart() {
      return this.dispChartFlag;
    },
    PriceRange() {
      var range = [];
      var fp = 0;
      if (this.fromPrice != 0) {
        fp = Number(this.fromPrice);
      } else {
        fp = this.getFromPrice();
      }
      var tp = 0;
      if (this.toPrice != 0) {
        tp = Number(this.toPrice);
      } else {
        tp = this.getToPrice();
      }

      for (var i = fp; i <= tp; i=i+this.step) {
        range.push(i);
      }
      return range;
    },
  },
  methods: {
    loadDataTable() {
      this.priceFrom = this.getFromPrice();
      this.priceTo = this.getToPrice();
      this.daysFrom = this.getDaysFrom();
      this.daysTo = this.getDaysTo();

      var row = 0;
      for (var i = this.daysFrom; i <= this.daysTo; i++) {
        row = i - this.daysFrom;
        this.datatable.push([]);
        for (var j = this.priceFrom; j <= this.priceTo; j = j+this.step) {
          this.datatable[row].push(
            this.getPortValue(i, j, this.iv, 1, this.positions,this.minToExp)
          );
        }
      }
      console.log(this.datatable);
      this.loadChartData();
      this.dispChartFlag = true;
    },
    loadChartData() {
      var list = [];
      for (var j = this.priceFrom; j <= this.priceTo; j=j+this.step) {
        list.push(j);
      }

      this.chartdata.labels = list;
      this.chartdata.datasets = [];

      for (var i = 0; i < this.datatable.length; i++) {
        var pldata = [];
        for (var k = 0; k < this.datatable[i].length; k++) {
          var pnl = this.datatable[i][k];
          pldata.push(pnl.pnl);
        }
        var col = "rgb(" + i * 5 + "0,132,1)";
        this.chartdata.datasets.push({
          data: pldata,
          fill: false,
          borderColor: [col],
          borderWidth: 1,
          label: "Day-" + (i + 1),
        });
      }
    },
    getFromPrice() {
      var fp = 1000000;
      if (this.fromPrice != 0) {
        fp = Number(this.fromPrice);
      } else {
        this.positions.forEach((ele) => {
          if (Number(ele.sp) < fp) {
            fp = Number(ele.sp);
          }
        });
      }
      return fp;
    },
    getToPrice() {
      var tp = 0;
      if (this.toPrice != 0) {
        tp = Number(this.toPrice);
      } else {
        this.positions.forEach((ele) => {
          if (Number(ele.sp) > tp) {
            tp = Number(ele.sp);
          }
        });
      }
      return tp;
    },
    getDaysFrom() {
      return 0;
    },
    getDaysTo() {

      var daysTo = this.dateCalculator(this.positions[0].xd);

      if (daysTo > 10) {
        daysTo = 10;
      }
      return daysTo;
    },
    getPortValue(d, m, vol, rate, table,min2Exp) {
      var totDer = 0;
      var totDelta = 0;
      var totVega = 0;
      var totGamma = 0;
      var totTheta = 0;
      var totCost = 0;
      var pnl = 0;

      table.forEach((ele) => {
        var sp = Number(ele.sp);
        var qu = Number(ele.xp);
        var optType = ele.cps;
        var cost = Number(ele.up);

        totCost = totCost + cost * qu;
        if (optType == "C" || optType == "P") {

          var dd = 0.5;
          if (d > 0) {
            dd = d;
          } else {
            dd = min2Exp / 390;
          }
          var options = this.optionCalculator(m, sp, rate, dd, vol);
          if (optType == "C") {
            totDer = totDer + options[0] * qu;
          } else {
            totDer = totDer + options[1] * qu;
          }
          totDelta = totDelta + options[2] * qu;
          totGamma = totGamma + options[3] * qu;
          totVega = totVega + options[4] * qu;
          totTheta = totTheta + options[5] * qu;

        } else {
          totDer = totDer + m * qu;
        }
      });

      pnl = totDer - totCost;
      console.log("Cost:" + totCost + "P&L:" + pnl);
      return {
        totalValue: Math.round(totDer, 2),
        totalDelta: Math.round(totDelta, 2),
        pnl: Math.round(pnl, 2),
      };
    },
  },
  created() {
    this.loadDataTable();
  },
};
</script>

<style scoped>
div.tableOuter {
  overflow: auto;
  margin-left: 250px;
}
table {
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid;
  border-collapse: collapse;
  border-color: rgba(121, 58, 6, 0.459);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 12px;
  overflow-x: auto;
  white-space: nowrap;
  
}

td,
th {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-collapse: collapse;
  border-color: rgba(7, 109, 29, 0.459);
}
p.gp {
  color: green;
}
p.rp {
  color: red;
}
p {
  font-weight: bold;
  font-size: 12px;
}

.chart {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
</style>