<template>
  <div class="home">
    <img alt="II888 Logo" src="../assets/ii888.jpg">
    <home-component msg="Welcome to Innovative Investments 888"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeComponent from '@/components/HomeComponent.vue'

export default {
  name: 'Home',
  components: {
    HomeComponent
  }
}
</script>
<style scoped>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 50%;
}
</style>