<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-card>
      <div>
        <label for="ticker">Ticker</label>
        <input
          id="ticker"
          type="text"
          v-model="ticker"
          class="l50"
          @change="resetData"
        />
        <p class="p-price" v-if="mp != 0">
          <b>Price: {{ this.mp }}</b>
        </p>
        <base-button mode="outline" @click.native="loadData"
          >Refresh</base-button
        >
        <base-button mode="outline" link to="/prospect">Prospects</base-button>
      </div>
    </base-card>
    <base-card v-if="DispChart">
      <div class="chart">
        <line-chart
          v-if="DispChart"
          :chartdata="chartdata"
          :options="options"
        />
      </div>
    </base-card>
    <base-card v-if="DispChart">
      <p>Total Call Open Int: {{ this.GetTotalCallVol }}</p>
      <p>Total Put Open Int: {{ this.GetTotalPutVol }}</p>
    </base-card>
    <base-card v-if="DispChart">
      <div class="tblctn">
        <table>
          <thead>
            <tr>
              <th>Call Vol</th>
              <th>Call Op Int</th>
              <th>Strike</th>
              <th>Put Vol</th>
              <th>Put Op Int</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, ind) in OptionTable" :key="ind">
              <td>{{ row.callVol }}</td>
              <td>{{ row.callOI }}</td>
              <td>
                <b> {{ row.strike }} </b>
              </td>
              <td>{{ row.putVol }}</td>
              <td>{{ row.putOI }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </base-card>
    <base-card v-if="DispChart">
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p>IRON CONDOR TRADE</p>
      <label for="tradename">Name</label>
      <input id="tradename" type="text" v-model="tradename" class="l50" />
      <label for="strike">Strike</label>
      <input id="strike" type="text" v-model="strike" class="l50" />
      <label for="spread">Spread</label>
      <input id="spread" type="text" v-model="spread" class="l50" />
      <br />
      <base-button @click.native="populateICData">Shop</base-button>
      <br />
      <label for="netcredit">Net Credit</label>
      <input id="netcredit" type="text" v-model="netcredit" class="l50" />
      <label for="netrevs">Net revenue</label>
      <input id="netrevs" type="text" v-model="netrevs" class="l50" />
      <label for="netcost">Net cost</label>
      <input id="netcost" type="text" v-model="netcost" class="l50" />

      <label for="numcontracts">Number of contracts</label>
      <input id="numcontracts" type="text" v-model="noctr" class="l50" />
      <label for="placetrade" class="ilb">Place trade on TDA?</label>
      <input id="placetrade" type="checkbox" v-model="placetrade" class="ilb" />
      <label for="createPortfolio" class="ilb"
        >Create portfolio on ii888?</label
      >
      <input
        id="createPortfolio"
        type="checkbox"
        v-model="createPortfolio"
        class="ilb"
      />
      <br />
      <base-button @click.native="placeICTrade">Trade</base-button>
      <base-button @click.native="scrollToTop">Top</base-button>
      <br />
      <p>Max-loss: {{ maxLoss }}</p>
    </base-card>
    <base-card v-if="DispChart">
      <label for="tradeyearmonthday">Option series after (YYYY-mm-dd)</label>
      <input
        id="tradeyearmonthday"
        type="text"
        v-model="tradeyearmonthday"
        class="l50"
      />
      <base-button @click.native="exploreHedges">Explore Hedges</base-button>
    </base-card>
    <base-card v-if="DispHedge">
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p>BULLISH DEBIT SPREAD</p>
      <label for="dsbulltradename">Name</label>
      <input
        id="dsbulltradename"
        type="text"
        v-model="dsBullTradename"
        class="l50"
      />

      <label for="bulldelta">Target delta</label>
      <input id="bulldelta" type="text" v-model="bulldelta" class="l50" />
      <br />
      <label for="bullnetdebit">Net Debit</label>
      <input id="bullnetdebit" type="text" v-model="bullnetdebit" class="l50" />
      <p class="pwrap">{{ this.bulldstrade }}</p>
      <label for="bullnumcontracts">Number of contracts</label>
      <input
        id="bullnumcontracts"
        type="text"
        v-model="bullnumcontracts"
        class="l50"
      />
      <label for="bullplacetrade" class="ilb">Place trade on TDA?</label>
      <input
        id="bullplacetrade"
        type="checkbox"
        v-model="bullplacetrade"
        class="ilb"
      />
      <br />
      <base-button @click.native="bullPlaceDSTrade">Trade</base-button>
      <br />
      <p>Volatility spread: {{ bullvolSpread }}</p>
    </base-card>
    <base-card v-if="DispHedge">
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p>BEARISH DEBIT SPREAD</p>
      <label for="dsbeartradename">Name</label>
      <input
        id="dsbeartradename"
        type="text"
        v-model="dsBearTradename"
        class="l50"
      />

      <label for="beardelta">Target delta</label>
      <input id="beardelta" type="text" v-model="beardelta" class="l50" />
      <br />
      <label for="bearnetdebit">Net Debit</label>
      <input id="bearnetdebit" type="text" v-model="bearnetdebit" class="l50" />
      <p class="pwrap">{{ this.beardstrade }}</p>
      <label for="bearnumcontracts">Number of contracts</label>
      <input
        id="bearnumcontracts"
        type="text"
        v-model="bearnumcontracts"
        class="l50"
      />
      <label for="bearplacetrade" class="ilb">Place trade on TDA?</label>
      <input
        id="bearplacetrade"
        type="checkbox"
        v-model="bearplacetrade"
        class="ilb"
      />
      <br />
      <base-button @click.native="bearPlaceDSTrade">Trade</base-button>
      <br />
      <p>Volatility spread: {{ bearvolSpread }}</p>
    </base-card>
  </div>
</template>
<script>
import myOptionCalcMixins from "../mixins/OptionCalculator.js";
import myMixins from "../mixins/GetQuote.js";
import LineChart from "./ui/Chart.vue";
import myMixinsCO from "../mixins/CreateOrder.js";

export default {
  props: ["tck"],
  mixins: [myMixins, myOptionCalcMixins, myMixinsCO],
  components: { LineChart },
  data() {
    return {
      ticker: this.tck,
      mp: 0,
      dispChartFlag: false,
      dispHedgeFlag: false,
      isSaving: false,
      chartdata: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ],
        },
      },
      optionTable: [],
      calls: [],
      puts: [],
      futureCalls: [],
      futurePuts: [],
      port: [],
      dsCport: [],
      dsPport: [],
      error: "",
      tradename: "",
      strike: 0,
      spread: 0,
      netcredit: 0,
      netrevs: 0,
      netcost: 0,
      noctr: 1,
      placetrade: "",
      createPortfolio: "",

      tradeyearmonthday: "",

      dsBullTradename: "",
      bulldelta: 0,
      bullnetdebit: 0,
      bullnumcontracts: 1,
      bullplacetrade: "",
      bullvolSpread: 0,
      bulldstrade: "",

      dsBearTradename: "",
      beardelta: 0,
      bearnetdebit: 0,
      bearnumcontracts: 1,
      bearplacetrade: "",
      bearvolSpread: 0,
      beardstrade: "",
    };
  },
  computed: {
    maxLoss() {
      return (
        Math.round((this.spread - this.netcredit + Number.EPSILON) * 100) / 100
      );
    },
    GetTotalCallVol() {
      var totCallVol = 0;
      this.optionTable.forEach((row) => {
        totCallVol += row.callOI;
      });
      return totCallVol;
    },
    GetTotalPutVol() {
      var totPutVol = 0;
      this.optionTable.forEach((row) => {
        totPutVol += row.putOI;
      });
      return totPutVol;
    },
    DispChart() {
      return this.dispChartFlag;
    },
    DispHedge() {
      return this.dispHedgeFlag;
    },
    OptionTable() {
      return this.optionTable;
    },
  },
  methods: {
    async exploreHedges() {
      this.dispHedgeFlag = false;
      this.isSaving = true;
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      await this.getOptionChain(token, this.ticker.toUpperCase());

      this.dsBullTradename = this.ticker + "-ds-c-" + this.getToday();
      this.bulldelta = 10;
      var spCallLong = this.getCallStrike(this.futureCalls, 0.2);
      if (spCallLong.sp === 0) {
        this.error = "No options available";
        return;
      }
      var spCallShort = this.getCallStrike(this.futureCalls, 0.1);
      if (spCallShort.sp === 0) {
        this.error = "No options available";
        return;
      }

      console.log("Long call:", spCallLong);
      console.log("Short call:", spCallShort);
      this.bullnetdebit = spCallLong.up - spCallShort.up;
      this.bullnetdebit =
        Math.round((this.bullnetdebit + Number.EPSILON) * 100) / 100;
      this.bullvolSpread =
        Math.round(
          (Number(spCallShort.volatility) -
            Number(spCallLong.volatility) +
            Number.EPSILON) *
            100
        ) / 100;
      this.dsCport = [
        {
          sp: spCallLong.sp,
          xd: spCallLong.xd,
          xp: 100,
          up: spCallLong.up,
          oc: "O",
          cps: "C",
          symbol: spCallLong.symbol,
        },
        {
          sp: spCallShort.sp,
          xd: spCallShort.xd,
          xp: -100,
          up: spCallShort.up,
          oc: "O",
          cps: "C",
          symbol: spCallShort.symbol,
        },
      ];
      this.bulldstrade = JSON.stringify(this.dsCport);

      this.dsBearTradename = this.ticker + "-ds-p-" + this.getToday();
      this.beardelta = -10;
      var spPutLong = this.getPutStrike(this.futurePuts, -0.2);
      if (spPutLong.sp === 0) {
        this.error = "No options available";
        return;
      }
      var spPutShort = this.getPutStrike(this.futurePuts, -0.1);
      if (spPutShort.sp === 0) {
        this.error = "No options available";
        return;
      }
      this.bearnetdebit = spPutLong.up - spPutShort.up;
      this.bearnetdebit =
        Math.round((this.bearnetdebit + Number.EPSILON) * 100) / 100;
      this.bearvolSpread =
        Math.round(
          (Number(spPutShort.volatility) -
            Number(spPutLong.volatility) +
            Number.EPSILON) *
            100
        ) / 100;

      this.dsPport = [
        {
          sp: spPutLong.sp,
          xd: spPutLong.xd,
          xp: 100,
          up: spPutLong.up,
          oc: "O",
          cps: "P",
          symbol: spPutLong.symbol,
        },
        {
          sp: spPutShort.sp,
          xd: spPutShort.xd,
          xp: -100,
          up: spPutShort.up,
          oc: "O",
          cps: "P",
          symbol: spPutShort.symbol,
        },
      ];

      this.beardstrade = JSON.stringify(this.dsPport);
      this.isSaving = false;
      this.dispHedgeFlag = true;
    },
    async getOptionChain(token, ticker) {
      if (this.tradeyearmonthday === "") {
        var dt = new Date();
        dt.setDate(dt.getDate() + 120);
        this.tradeyearmonthday = dt
          .toLocaleDateString("pt-br")
          .split("/")
          .reverse()
          .join("-");
      }

      console.log("Get Option chain: " + ticker);
      var json = await this.getFutureQuote(
        token,
        ticker.toUpperCase(),
        this.tradeyearmonthday
      );

      this.futureCalls = [];
      this.futurePuts = [];
      var callChain = json.callExpDateMap;
      for (const xp in callChain) {
        for (const sp in callChain[xp]) {
          this.futureCalls.push({
            sp: sp,
            bid: callChain[xp][sp][0]["bid"],
            ask: callChain[xp][sp][0]["ask"],
            symbol: callChain[xp][sp][0]["symbol"],
            volatility: callChain[xp][sp][0]["volatility"],
            delta: callChain[xp][sp][0]["delta"],
            xd: xp.split(":")[0],
            openInterest: callChain[xp][sp][0]["openInterest"],
            up:
              (Number(callChain[xp][sp][0]["bid"]) +
                Number(callChain[xp][sp][0]["ask"])) /
              2,
          });
        }
        break;
      }
      var putChain = json.putExpDateMap;
      for (const xp in putChain) {
        for (const sp in putChain[xp]) {
          this.futurePuts.push({
            sp: sp,
            bid: putChain[xp][sp][0]["bid"],
            ask: putChain[xp][sp][0]["ask"],
            symbol: putChain[xp][sp][0]["symbol"],
            volatility: putChain[xp][sp][0]["volatility"],
            delta: putChain[xp][sp][0]["delta"],
            xd: xp.split(":")[0],
            openInterest: putChain[xp][sp][0]["openInterest"],
            up:
              (Number(putChain[xp][sp][0]["bid"]) +
                Number(putChain[xp][sp][0]["ask"])) /
              2,
          });
        }
        break;
      }
      console.log(json);
    },
    getCallStrike(series, del) {
      var sp = 0;
      var symbol = null;
      var up = 0;
      var xd = null;
      var volatility = 0;

      series.forEach((ele, i) => {
        var delta = Number(del) - Number(ele.delta);
        if (delta > 0 && sp == 0) {
          if (Math.abs(delta) < Math.abs(Number(series[i - 1].delta) - del)) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
            up = ele.up;
            xd = ele.xd;
            volatility = ele.volatility;
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
            up = series[i - 1].up;
            xd = series[i - 1].xd;
            volatility = series[i - 1].volatility;
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return { sp: sp, symbol: symbol, up: up, xd: xd, volatility: volatility };
    },
    getPutStrike(series, del) {
      var sp = 0;
      var symbol = null;
      var up = 0;
      var xd = null;
      var volatility = 0;

      series.forEach((ele, i) => {
        var delta = Math.abs(Number(del)) - Math.abs(Number(ele.delta));
        if (delta < 0 && sp == 0) {
          if (
            Math.abs(delta) <
            Math.abs(Math.abs(Number(series[i - 1].delta)) - del)
          ) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
            up = ele.up;
            xd = ele.xd;
            volatility = ele.volatility;
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
            up = series[i - 1].up;
            xd = series[i - 1].xd;
            volatility = series[i - 1].volatility;
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return { sp: sp, symbol: symbol, up: up, xd: xd, volatility: volatility };
    },
    async bullPlaceDSTrade() {
      this.isSaving = true;
      let today = new Date();

      const prospect = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.dsBullTradename,
        hvol: 0,
        port: this.dsCport,
        ticker: this.ticker,
      };
      console.log(prospect);
      this.isLoading = true;
      await this.$store.dispatch("portfolio/registerPortfolio", prospect);
      console.log("Done creating the trade and saving in portfolio table");

      var token = this.$store.getters.tdatoken;
      // var tdacct = this.$store.getters.tdacct;
      console.log("Placetrade flag:", this.placetrade);
      var net = this.bullnetdebit;
      if (this.placetrade == true) {
        await this.createDSOrder(token, prospect, net);
        console.log("Created order on TDA - Yayyy!!");
      }

      this.isSaving = false;
    },
    async bearPlaceDSTrade() {
      this.isSaving = true;
      let today = new Date();

      const prospect = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.dsBearTradename,
        hvol: 0,
        port: this.dsPport,
        ticker: this.ticker,
      };
      console.log(prospect);
      this.isLoading = true;
      await this.$store.dispatch("portfolio/registerPortfolio", prospect);
      console.log("Done creating the trade and saving in portfolio table");

      var token = this.$store.getters.tdatoken;
      // var tdacct = this.$store.getters.tdacct;
      console.log("Placetrade flag:", this.placetrade);
      var net = this.bearnetdebit;
      if (this.placetrade == true) {
        await this.createDSOrder(token, prospect, net);
        console.log("Created order on TDA - Yayyy!!");
      }

      this.isSaving = false;
    },
    handleError() {
      this.error = null;
    },
    async loadData() {
      this.ticker = this.ticker.toUpperCase();
      this.resetData();
      await this.loadChartData();
      await this.loadOptionsData();
      await this.populateICData();
      this.dispChartFlag = !this.dispChartFlag;
    },
    populateICData() {
      this.isSaving = true;
      this.tradename = this.ticker + "-ic-" + this.getNextFrday();

      var str = this.strike;
      if (str === 0) {
        str = this.mp;
      }
      var spShortCall = this.getStrike(this.calls, str);
      var spShortPut = this.getStrike(this.puts, str);

      if (spShortCall.sp === 0 || spShortPut.sp === 0) {
        this.error = "No options available";
        return;
      } else {
        this.strike = spShortCall.sp;
      }
      var callPrice = this.getOptPrice(this.calls, spShortCall.sp);
      var putPrice = this.getOptPrice(this.puts, spShortPut.sp);

      var spr = this.spread;
      if (spr === 0) {
        spr = 10;
        this.spread = spr;
      }
      var spCallLong = this.getStrike(
        this.calls,
        Number(spShortCall.sp) + Number(spr)
      );
      var spPutLong = this.getStrike(
        this.puts,
        Number(spShortPut.sp) - Number(spr)
      );
      var callPriceLong = this.getOptPrice(this.calls, spCallLong.sp);
      var putPriceLong = this.getOptPrice(this.puts, spPutLong.sp);

      this.netcredit = callPrice + putPrice - callPriceLong - putPriceLong;
      this.netrevs = callPrice + putPrice;
      this.netcost = callPriceLong + putPriceLong;
      this.netcredit =
        Math.round((this.netcredit + Number.EPSILON) * 100) / 100;
      this.netrevs = Math.round((this.netrevs + Number.EPSILON) * 100) / 100;
      this.netcost = Math.round((this.netcost + Number.EPSILON) * 100) / 100;
      this.port = [
        {
          sp: spShortCall.sp,
          xd: this.getNextFrday(),
          xp: -100,
          up: callPrice,
          oc: "O",
          cps: "C",
          symbol: spShortCall.symbol,
        },
        {
          sp: spShortPut.sp,
          xd: this.getNextFrday(),
          xp: -100,
          up: putPrice,
          oc: "O",
          cps: "P",
          symbol: spShortPut.symbol,
        },
        {
          sp: spCallLong.sp,
          xd: this.getNextFrday(),
          xp: 100,
          up: callPriceLong,
          oc: "O",
          cps: "C",
          symbol: spCallLong.symbol,
        },
        {
          sp: spPutLong.sp,
          xd: this.getNextFrday(),
          xp: 100,
          up: putPriceLong,
          oc: "O",
          cps: "P",
          symbol: spPutLong.symbol,
        },
      ];
      console.log("Portfolio:", this.port);
      this.isSaving = false;
    },
    async placeICTrade() {
      this.isSaving = true;
      let today = new Date();

      console.log("Numcontracts:", this.noctr);

      var longExp = 100 * this.noctr;
      var shortExp = -100 * this.noctr;
      if (this.noctr !== 1) {
        this.port[0].xp = shortExp;
        this.port[1].xp = shortExp;
        this.port[2].xp = longExp;
        this.port[3].xp = longExp;
      }

      const prospect = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.tradename,
        hvol: 0,
        port: this.port,
        ticker: this.ticker,
      };
      console.log(prospect);
      this.isLoading = true;
      if (this.createPortfolio == true) {
        await this.$store.dispatch("portfolio/registerPortfolio", prospect);
        console.log("Done creating the trade and saving in portfolio table");
      }

      var token = this.$store.getters.tdatoken;
      //var tdacct = this.$store.getters.tdacct;
      console.log("Placetrade flag:", this.placetrade);
      var net = this.netcredit * -1;
      if (this.placetrade == true) {
        await this.createOrder(token, prospect, net);
        console.log("Created order on TDA - Yayyy!!");
      }
      // var watchlist = {
      //   name: "ii888",
      //   watchlistId: "1974731808",
      //   watchlistItems: [
      //     {
      //       instrument: {
      //         symbol: this.ticker,
      //         assetType: "EQUITY",
      //       },
      //     },
      //   ],
      // };

      // const response = await fetch(
      //   `https://api.tdameritrade.com/v1/accounts/${tdacct}/watchlists/1974731808`,
      //   {
      //     method: "PATCH",
      //     headers: {
      //       Authorization: token,
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(watchlist),
      //   }
      // );
      // console.log("Response from WL update", response);
      this.isSaving = false;
    },
    getStrike(series, mp) {
      var sp = 0;
      var symbol = null;
      console.log("Market Price:", mp);
      series.forEach((ele, i) => {
        var delta = Number(mp) - Number(ele.sp);
        if (delta < 0 && sp == 0) {
          if (Math.abs(delta) < Math.abs(Number(series[i - 1].sp) - mp)) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return { sp: sp, symbol: symbol };
    },
    getOptPrice(series, sp) {
      var price = 0;
      series.forEach((ele) => {
        if (Number(ele.sp) === Number(sp) && price === 0) {
          price = (Number(ele.ask) + Number(ele.bid)) / 2;
          return;
        }
      });
      price = Math.round(price * 100, 2) / 100;
      return price;
    },
    resetData() {
      this.dispChartFlag = false;
      this.dispHedgeFlag = false;
      this.mp = 0;
      this.strike = 0;
      this.calls = [];
      this.puts = [];
      this.futureCalls = [];
      this.futurePuts = [];
      this.optionTable = [];
    },
    async loadChartData() {
      var list = [];
      var closing = [];

      var histQuotes = {};
      histQuotes = await this.getHistQuotes();

      if (histQuotes !== {}) {
        histQuotes.candles.forEach((quote) => {
          closing.push(quote.close);
          var date = new Date(quote.datetime).toLocaleDateString("en-US");
          list.push(date);
        });
      }

      this.chartdata.labels = list;
      this.chartdata.datasets = [];

      this.chartdata.datasets.push({
        data: closing,
        fill: false,
        borderColor: "#2554FF",
        borderWidth: 1,
        label: "Closing (6 months)",
      });
      console.log(this.chartdata);
    },
    getNextFrday() {
      var date = new Date();
      var resultDate = new Date();
      if (resultDate.getDay() === 5) {
        resultDate.setDate(date.getDate() + 7);
      } else {
        resultDate.setDate(date.getDate() + ((7 + 5 - date.getDay()) % 7));
      }
      var formattedDate = resultDate
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      return formattedDate;
    },
    async loadOptionsData() {
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      var json = await this.getQuote(
        token,
        this.ticker.toUpperCase(),
        this.getNextFrday(),
        this.getNextFrday()
      );
      this.mp = json.underlyingPrice;

      this.calls = [];
      this.puts = [];
      var callChain = json.callExpDateMap;
      for (const xp in callChain) {
        for (const sp in callChain[xp]) {
          this.calls.push({
            sp: sp,
            bid: callChain[xp][sp][0]["bid"],
            ask: callChain[xp][sp][0]["ask"],
            symbol: callChain[xp][sp][0]["symbol"],
            openInterest: callChain[xp][sp][0]["openInterest"],
            totalVolume: callChain[xp][sp][0]["totalVolume"],
          });
        }
      }
      var putChain = json.putExpDateMap;
      for (const xp in putChain) {
        for (const sp in putChain[xp]) {
          this.puts.push({
            sp: sp,
            bid: putChain[xp][sp][0]["bid"],
            ask: putChain[xp][sp][0]["ask"],
            symbol: putChain[xp][sp][0]["symbol"],
            openInterest: putChain[xp][sp][0]["openInterest"],
            totalVolume: putChain[xp][sp][0]["totalVolume"],
          });
        }
      }
      this.calls.forEach((call) => {
        this.puts.forEach((put) => {
          if (call.sp == put.sp) {
            this.optionTable.push({
              callVol: call.totalVolume,
              callOI: call.openInterest,
              strike: call.sp,
              putVol: put.totalVolume,
              putOI: put.openInterest,
            });
          }
        });
      });

      console.log(json);
    },
    async getHistQuotes() {
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      const response = await fetch(
        //`https://api.tdameritrade.com/v1/marketdata/${this.ticker}/pricehistory?periodType=month&period=6&frequencyType=daily`,
        `https://api.schwabapi.com/marketdata/v1/pricehistory?symbol=${this.ticker}&periodType=month&period=6&frequencyType=daily`, 
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const json = await response.json();

      if (!response.ok) {
        const error = new Error(json.message || "Failed to fetch!");
        throw error;
      }

      console.log(json);

      return json;
    },
    getToday() {
      var date = new Date();

      var formattedDate = date
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      return formattedDate;
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
  },
};
</script>

<style scoped>
.json-popup {
  position: relative;
  text-align: center;
  width: 50%;
  opacity: 1;
}
.form-popup {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-45%, 5%);
  border: 2px solid rgb(100, 24, 24);
  z-index: 9;
  opacity: 1;
  padding: 20px 20px;
  background-color: rgb(233, 241, 234);
}
.form-container {
  opacity: 1;
}

input,
label {
  width: 100%;
  border: none;
  text-align: left;
  vertical-align: middle;
}

input.ilb {
  width: 10%;
  border: 1px solid;
  text-align: left;
  margin-bottom: 0.8rem;
  display: inline-block;
}

input.l50 {
  width: 100%;
  border: 1px solid;
  text-align: left;
  margin-bottom: 0.8rem;
}

label.ilb {
  margin-left: 20px;
  width: 30%;
  display: inline-block;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.1rem;
}

.tblctn {
  height: 600px;
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
th {
  background: rgb(109, 178, 238);
  padding: 5px 5px;
}
td {
  padding: 1px 1px;
}
td,
th {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-collapse: collapse;
  border-color: rgba(7, 109, 29, 0.459);
}
p.gp {
  color: green;
}
p.rp {
  color: red;
}

p.pwrap {
  word-wrap: break-word;
  font-size: 10px;
}

p {
  font-weight: bold;
  font-size: 20px;
}

.chart {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
</style>