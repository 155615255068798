<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    }
  }
}
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.2rem 0.2rem;
  font: inherit;
  font-weight: bold;
  font-size: 12px;

  background-color: #05420d;
  border: 1px solid #05420d;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 0.1rem;
  display: inline-block;
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: #c3e9b9;
  border-color: #1a128d;
}

.flat {
  background-color: transparent;
  color: #033d25;
  border: none;
}

.outline {
  background-color: transparent;
  border-color: #033d25;
  color: #033d25;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: #b3dac9;
}
</style>