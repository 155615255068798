import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth/index.js';
import prospectModule from './modules/prospects/index.js'
import pnlModule from './modules/pnl/index.js'
import hvolModule from './modules/iv/index.js'
import portfolioModule from './modules/portfolio/index.js'
import miscModule from './modules/misc/index.js'
import noteModule from './modules/notes/index.js'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: authModule,
    prospect: prospectModule,
    pnl: pnlModule,
    hvol: hvolModule,
    portfolio: portfolioModule,
    misc: miscModule,
    notes: noteModule
  }
});
