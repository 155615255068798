export default {
    getNotes(state) {
        if (JSON.stringify(state.notes) == "[]") {
            state.notes = [];
        }
        return state.notes;
    },
    hasNotes(state) {
        if (JSON.stringify(state.notes) == "[]") {
            return false;
        } else {
            return true;
        }
    },
};