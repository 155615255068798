export default {
    async registerProspect(context, data) {

        const token = context.rootGetters.token;
        const prospectData = {
            owner: token,
            ticker: data.ticker,
            erdate: data.erdate,
            status: data.status,
            qtr: data.qtr,
            ba: data.ba,
            name: data.name
        };

        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/prospect',
            {
                "headers": {
                    "Content-Type": "text/plain"
                },
                method: 'PUT',
                body: JSON.stringify(prospectData)
            }
        );

        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to update!');
            throw error;
        }

        context.commit('updateProspect', {
            ...prospectData
        });
    },
    async setProspects(context, payload) {
        console.log(payload);
        const token = context.rootGetters.token;
        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/prospect',
            {
                method: 'POST',
                "headers": {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({ owner: token,
                qtr:payload.qtr})
            }
        );
        var responseData = await response.json();
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to fetch!');
            throw error;
        }

        var prospects = [];
        for (var i = 0; i < responseData.data.Items.length; i++) {
            prospects.push(responseData.data.Items[i]);
        }
        context.commit('setProspects', prospects);
    },
    async removeProspects(context, payload) {
        const token = context.rootGetters.token;
        payload.status = 'Closed';
        payload.owner = token;

        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/prospect',
            {
                "headers": {
                    "Content-Type": "text/plain"
                },
                method: 'PUT',
                body: JSON.stringify(payload)
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to remove!');
            throw error;
        }
        context.commit('removeProspectByName', payload);
    },
};
