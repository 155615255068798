export default {
    getAllProspects(state) {
        return state.prospects;
    },
    getProspectByName(state, payload) {
        state.prospects.find((pros) => {
            if (pros.name === payload.name) {
                return pros;
            }
        })
    },
    hasProspects(state) {
        return state.prospects && state.prospects.length > 0;
    },
};