export default {
    async setPnLs(context, payload) {

        console.log(payload);
        const token = context.rootGetters.token;
        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/pnl',
            {
                method: 'POST',
                "headers": {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({ owner: token,
                qtr:payload.qtr})
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to fetch!');
            throw error;
        }

        const pnls = [];

        for (var i = 0; i < responseData.data.Items.length; i++) {
            console.log('got pnl');
            pnls.push(responseData.data.Items[i]);
        }
        console.log(pnls);
        context.commit('setPnLs', pnls);
    },
    async postPnl(context, data) {

        const token = context.rootGetters.token;
        const pnlData = {
            owner: token,
            ticker: data.ticker,
            opendate: data.opendate,
            closedate: data.closedate,
            tradename: data.tradename,
            amount: data.amount,
            capital: data.capital,
            margin: data.margin,
            status: data.status,
            qtr: data.qtr
        };

        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/pnl',
            {
                "headers": {
                    "Content-Type": "text/plain"
                },
                method: 'PUT',
                body: JSON.stringify(pnlData)
            }
        );

        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to update!');
            throw error;
        }

        context.commit('updatePnl', {
            ...pnlData
        });
    },
};
