<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <div v-if="showLedgerPostDialog" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="post2Ledger">
          <h4>Please enter the quarter to post to</h4>
          <input v-model="qtr" id="qtr" class="T4" />
          <br />
          <br />
          <base-button type="submit">Post</base-button>
          <base-button type="button" @click.native="closePost2LedgerDialog"
            >Close</base-button
          >
        </form>
      </div>
    </div>
    <div v-if="showParmsDlg" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="setParms">
          <label class="T30">From price</label>
          <input v-model="fromPrice" id="fromPrice" class="T4" />

          <label class="T30">To price</label>
          <input v-model="toPrice" id="toPrice" class="T4" />

          <label class="T30">Step</label>
          <input v-model="step" id="step" class="T4" />

          <label class="T30">Minutes to expiration</label>
          <input v-model="minToExp" id="minToExp" class="T4" />

          <label class="T30">Implied vol</label>
          <input v-model="iv" id="iv" class="T4" />
          <br />
          <br />
          <base-button type="submit">Set</base-button>
          <base-button type="button" @click.native="closeParmsDlg"
            >Close</base-button
          >
        </form>
      </div>
    </div>
       <div v-if="showLotDlg" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container">
          <h4>Please enter lot size</h4>
          <input v-model="lotSize" id="lotSize" class="T4" />
          <h4>Please enter lot number</h4>
          <input v-model="lotNbr" id="lotNbr" class="T4" />
          <h4>Please enter tradename</h4>
          <input v-model="newTradename" id="newTradename" class="T15" />
          <br />
          <br />
          <base-button type="button" @click.native="addLot"
            >Add Lot</base-button
          >
          <base-button type="button" @click.native="splitLot"
            >Split Lot</base-button
          >
          <base-button type="button" @click.native="closeLotDlg"
            >Close</base-button
          >
        </form>
      </div>
    </div>
    <div v-if="dispNoteDialog" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="addNote">
          <h4>Please enter your Note here</h4>
          <textarea
            v-model="note"
            spellcheck="false"
            id="note"
            rows="10"
            cols="80"
          ></textarea>
          <br />
          <br />
          <base-button type="submit">Add</base-button>
          <base-button type="button" @click.native="closeNoteDialog"
            >Close</base-button
          >
        </form>
      </div>
    </div>
    <div v-if="dispImpDialog" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="importForm">
          <h4>Please enter your JSON here</h4>
          <textarea
            v-model="json"
            spellcheck="false"
            id="tradeJSON"
            rows="20"
            cols="80"
          ></textarea>
          <br />
          <br />
          <base-button type="submit">Import</base-button>
          <base-button type="button" @click.native="closeImportDialog"
            >Close</base-button
          >
        </form>
      </div>
    </div>
    <base-card>
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p v-bind:class="{ loss: pnl < 0, profit: pnl >= 0 }">P&L: {{ pnl }}</p>
      <p>Capital: {{ capital }} | Margin: {{ margin }}</p>
      <p>Spread: {{ spread }}</p>
      <p>
        Cost Basis: {{ net }} | Total Exposure: {{ totalExposure.toFixed(2) }}
        </p>
      <div>
            <table>
          <thead>
            <tr>
              <th class="T4">Ticker</th>
              <th class="T4">Type</th>
              <th class="T4">C|P</th>
              <th class="T6">Date</th>
              <th class="T4">Ser Num</th>
            </tr>
          </thead>
            <tr>
              <td class="T4">
                <input class="T4" type="text" v-model="tn_ticker" @change="tradenamebuilder"/>
              </td>
              <td class="T4">
                <input class="T4" type="text" v-model="tn_type"  @change="tradenamebuilder"/>
              </td>
              <td class="T4">
                <input class="T4" type="text" v-model="tn_cp"  @change="tradenamebuilder"/>
              </td>
              <td class="T6">
                <input class="T6" type="text" v-model="tn_date"  @change="tradenamebuilder"/>
              </td>
              <td class="T4">
                <input class="T4" type="number" v-model="tn_ser"  @change="tradenamebuilder"/>
              </td>
            </tr>
        </table>
        <br />

        <label for="tradename">Name</label>
        <input id="tradename" type="text" v-model="tradename" class="l50" />
        <br />
        <label for="hvol">Volatility</label>
        <input id="hvol" type="text" v-model="hvol" class="l50" />

        <br /><br />
        <base-button mode="outline" @click.native="savePortfolio"
          >Save</base-button
        >
        <base-button mode="outline" @click.native="openImportDialog"
          >Import</base-button
        >
        <base-button mode="outline" @click.native="exportPortfolio"
          >Export</base-button
        >
        <base-button mode="outline" @click.native="genClose"
          >GenClose</base-button
        >
        <base-button mode="outline" @click.native="postToLedger"
          >Post2P&L</base-button
        >
      
        <base-button mode="outline" @click.native="addPosition"
          >+Position</base-button
        >
        <base-button mode="outline" @click.native="calcGreeks"
          >Greeks</base-button
        >
        <base-button mode="outline" @click.native="showAnalysisView"
          >Analyse</base-button
        >
        <base-button mode="outline" @click.native="showParmsDialog"
          >Parms</base-button
        >
        <base-button mode="outline" @click.native="showNoteDialog"
          >+Note</base-button
        >
        <base-button mode="outline" link to="/portfolio">Return</base-button>
        <base-button mode="outline" @click.native="createFromOrder">CreateFromOrder</base-button>
        <base-button mode="outline" @click.native="updateFromOrder">UpdateFromOrder</base-button>
        <base-button mode="outline" @click.native="closeFromOrder">CloseFromOrder</base-button>
        <br /><br />
        <table v-if="Positions.length > 0">
          <thead>
            <tr>
              <th class="T4">Strike</th>
              <th class="T15">Exp</th>
              <th class="T4">Size</th>
              <th class="T4">C|P|S</th>
              <th class="T6">Unit Price</th>
              <th class="T4">O/C</th>
              <th class="T4">Del</th>
              <th class="T5">Gam</th>
              <th class="T5">Vega</th>
              <th class="T5">The</th>
              <th class="T4">Act</th>
            </tr>
          </thead>
          <div v-for="(pos, i) in Positions" :key="i">
            <tr>
              <td class="T4">
                <input class="T4" type="text" v-model="pos.sp" />
              </td>
              <td class="T15">
                <input class="T15" type="date" v-model="pos.xd" />
              </td>
              <td class="T4">
                <input class="T4" type="text" v-model="pos.xp" />
              </td>
              <td class="T4">
                <input class="T4" type="text" v-model="pos.cps" />
              </td>
              <td class="T6">
                <input class="T6" type="text" v-model="pos.up" />
              </td>
              <td class="T4">
                <input class="T4" type="text" v-model="pos.oc" />
              </td>
              <td class="T4">
                <label class="T4"> {{ pos.delta }}</label>
              </td>
              <td class="T4">
                <label class="T4"> {{ pos.gamma }}</label>
              </td>
              <td class="T4">
                <label class="T4"> {{ pos.theta }}</label>
              </td>
              <td class="T4">
                <label class="T4"> {{ pos.vega }}</label>
              </td>
              <td class="T10">
                <base-button mode="flat" @click.native="deleteItem(i)"
                  >Delet</base-button
                >
              </td>
            </tr>
          </div>
        </table>
      </div>
    </base-card>
    <base-card v-if="hasNotes">
      <div>
        <div v-for="(txt, i) in Notes" :key="i">
          <p>
            {{ txt }}
          </p>
        </div>
      </div>
    </base-card>
    <portfolio-analysis
      v-if="showAnalysis"
      :positions="this.positions"
      :hvol="this.hvol"
      :fromPrice="this.fromPrice"
      :toPrice="this.toPrice"
      :step="this.step"
      :minToExp="this.minToExp"
      :iv="this.iv"
    ></portfolio-analysis>
  </div>
</template>
<script>
import myMixins from "../mixins/GetQuote.js";
import PortfolioAnalysis from "./PortfolioAnalysis.vue";
export default {
  props: ["tn"],
  mixins: [myMixins],
  components: {
    PortfolioAnalysis,
  },
  data() {
    return {
      tn_ticker: "",
      tn_type: "ds",
      tn_cp: "C",
      tn_date: "",
      tn_ser: 1,
      tradename: "",
      positions: [],
      showImpDialog: false,
      showLedgerPostDialog: false,
      json: "",
      isSaving: false,
      qtr: "",
      mp: 0,
      hvol: 0,
      error: null,
      showAnalysisFlag: false,
      showParmsDlgFlag: false,
      showNoteDlgFlag: false,
      fromPrice: 0,
      toPrice: 0,
      step: 5,
      minToExp: 120,
      iv: 10,
      notes: [],
      note: "",
            showLot: false,
      lotSize: 0,
      lotNbr: 0,
      newTradename: "",
      ticker: "",
    };
  },
  computed: {
    tradetype() {
      if (this.tradename.includes("-er-")) {
        return "ER";
      } else if (this.tradename.includes("-ic-")) {
        return "IC";
      } else if (this.tradename.includes("-ds-")) {
        return "DS";
      } else if (this.tradename.includes("-bwbf-")) {
        return "BF";
      } else if (this.tradename.includes("-cs-")) {
        return "CS";
      }
      return "UN";
    },
    net() {
      var net = 0;
      var longXp = 0;
      var shortXp = 0;
      this.positions.forEach((p) => {
        if (p.oc == "O") {
          if (p.xp < 0) {
            shortXp += p.xp * -1;
          } else {
            longXp += p.xp * 1;
          }
        } else {
          if (p.xp < 0) {
            longXp -= p.xp * -1;
          } else {
            shortXp -= p.xp * 1;
          }
        }

        net += Number(p.up) * p.xp;
      });

      var netXp = 0;
      if (longXp > shortXp) {
        netXp = longXp - shortXp;
      } else if (longXp < shortXp) {
        netXp = shortXp - longXp;
      } else {
        netXp = longXp;
      }

      var cb = net;
      if (netXp > 0) {
        cb = net / netXp;
      }
      return this.myRound(cb);
    },
    totalExposure() {
      var net = 0;
      this.positions.forEach((p) => {
        net += Number(p.up) * p.xp;
      });

      return net;
    },
    pnl() {
      var portMatch = [];
      this.positions.forEach((port, idx) => {
        portMatch[idx] = this.portClose(port, this.positions);
      });

      console.log("portMatch:",portMatch);
      var totPnl = 0;
      portMatch.forEach((ele, idx) => {
        if (ele !== -1) {
          if (idx < ele) {
            var pnl =
              (Number(this.positions[ele].up) -
                Number(this.positions[idx].up)) *
              Number(this.positions[idx].xp);
            totPnl += pnl;
          }
        }
      });
      return totPnl.toFixed(2);
    },
    capital() {
      var capital = 0;
      this.positions.forEach((ele) => {
        if (ele.oc === "O") {
          capital = capital + Number(ele.up) * Number(ele.xp);
        }
      });
      return capital.toFixed(2);
    },
    margin() {
      var margin = 0;
      if (this.positions.length > 0) {
        if (typeof this.tradetype !== "undefined") {
          if (this.tradetype === "ER" || this.tradetype === "IC") {
            margin =
              (Number(this.positions[2].sp) - Number(this.positions[0].sp)) *
              Number(this.positions[2].xp);
          } else if (this.tradetype === "BF") {
            if (this.positions.length >= 3) {
              var sp1 = Math.abs(
                Number(this.positions[2].sp) - Number(this.positions[0].sp)
              );
              var sp2 = Math.abs(
                Number(this.positions[1].sp) - Number(this.positions[0].sp)
              );
              var sp = sp1 > sp2 ? sp1 : sp2;
              margin = sp * Number(this.positions[2].xp);
            }
          } else if (this.tradetype === "DS") {
            margin = 0;
          } else if (this.tradetype === "CS") {
            if (this.positions.length >= 2) {
              var sp3 = Math.abs(
                Number(this.positions[1].sp) - Number(this.positions[0].sp)
              );
              margin = Math.abs(sp3 * Number(this.positions[0].xp));
            }
          }
        }

      }

      console.log("Margin:" + margin);
      return margin;
    },
    spread() {
      var cs = 0;
      var ps = 0;
      var op = 0;
      this.positions.forEach((ele) => {
        if (ele.oc === "O") {
          if (Number(ele.xp) < 0) {
            op = Number(ele.up) * -1;
          } else {
            op = Number(ele.up);
          }
          if (ele.cps === "C") {
            cs = cs + op;
          } else if (ele.cps === "P") {
            ps = ps + op;
          }
        }
      });
      var cstr = "";
      if (cs !== 0) {
        cstr = "Call: " + cs.toFixed(2);
      }
      var pstr = "";
      if (ps !== 0) {
        pstr = "Put: " + ps.toFixed(2);
      }
      var tstr = "Total: " + (cs + ps).toFixed(2);
      return tstr + " | " + cstr + " | " + pstr;
    },
    Notes() {
      return this.notes;
    },
    hasNotes() {
      return this.$store.getters["notes/hasNotes"];
    },
    Positions() {
      return this.positions;
    },
    dispImpDialog() {
      return this.showImpDialog;
    },
    dispNoteDialog() {
      return this.showNoteDlgFlag;
    },
    dispPostLedgerDialog() {
      return this.showLedgerPostDialog;
    },
    showAnalysis() {
      return this.showAnalysisFlag;
    },
    showParmsDlg() {
      return this.showParmsDlgFlag;
    },
    showLotDlg() {
      return this.showLot;
  },
  },
  methods: {
    async createFromOrder() {
      await this.loadOrders();
      var Orders = this.$store.getters["orders/getAllOrders"];
      console.log("OrdersFrom Getter:",Orders);

      Orders.forEach((order) => {
        console.log("order for", order.orderLegCollection[0].instrument.underlyingSymbol);
        if (order.orderLegCollection[0].instrument.underlyingSymbol === this.ticker) {
          order.orderLegCollection.forEach((leg) => {
            order.orderActivityCollection.forEach((legExec) => {
                legExec.executionLegs.forEach((el) => {
                  if (leg.legId === el.legId) {
                    var oc = "C";
                    if (leg.instruction === "SELL_TO_OPEN" || leg.instruction === "BUY_TO_OPEN") {
                      oc = "O";
                    }
                    var cps = "C";
                    if (leg.instrument.putCall === "PUT") {
                      cps = "P";
                    }
                    var qty = leg.quantity * 100;
                    if (leg.instruction === "SELL_TO_OPEN" || leg.instruction === "SELL_TO_CLOSE") {
                      qty = qty * -1;
                    }
                    var sp = this.getSPFromSymbol(leg.instrument.symbol);
                    var xd = this.getXDFromSymbol(leg.instrument.symbol);
                    var p = {
                      "sp": sp,
                      "xd": xd,
                      "xp": qty,
                      "cps": cps,
                      "up": el.price,
                      "oc": oc,
                    }
                    this.positions.push(p);
                  }
                })
              })          

            })
        }
        
      })


    },
    async updateFromOrder() {
      await this.loadOrders();
      var Orders = this.$store.getters["orders/getAllOrders"];
      console.log("OrdersFrom Getter:",Orders);

      Orders.forEach((order) => {
        console.log("order for", order.orderLegCollection[0].instrument.underlyingSymbol);
        if (order.orderLegCollection[0].instrument.underlyingSymbol === this.ticker) {
          if (order.orderLegCollection.length === this.positions.length) {
            if (order.orderActivityCollection.length === 1) {
              order.orderActivityCollection[0].executionLegs.forEach((el) => {
                order.orderLegCollection.forEach((leg) => {
                  if (leg.legId === el.legId) {
                    console.log("Leg P/C:",leg.instrument.putCall);
                    console.log("Leg instr:",leg.instruction);
                    this.positions.forEach((pos) => {
                      if ((leg.instrument.putCall === "CALL" && pos.cps === "C" ||
                      leg.instrument.putCall === "PUT" && pos.cps === "P") && 
                      ((leg.instruction === "SELL_TO_OPEN" && pos.xp < 0 && pos.oc === "O") ||
                      (leg.instruction === "BUY_TO_OPEN" && pos.xp > 0 && pos.oc === "O"))
                      ) {
                        pos.up = el.price;
                        console.log("Updating unit price");
                      }
                    })
                  }
                })
              })
            } else {
              order.orderLegCollection.forEach((leg) => {
              order.orderActivityCollection.forEach((legExec) => {
                legExec.executionLegs.forEach((el) => {
                  if (leg.legId === el.legId) {
                    var oc = "O";
                    if (leg.instruction === "SELL_TO_CLOSE" || 
                      leg.instruction === "BUY_TO_CLOSE") {
                        oc = "C";
                    }
                    var cps = "C";
                    if (leg.instrument.putCall === "PUT") {
                      cps = "P";
                    }
                    var qty = leg.quantity * 100;
                    if (leg.instruction === "SELL_TO_OPEN") {
                      qty = qty * -1;
                    }
                    var sp = this.getSPFromSymbol(leg.instrument.symbol);
                    var xd = this.getXDFromSymbol(leg.instrument.symbol);
                    var p = {
                      "sp": sp,
                      "xd": xd,
                      "xp": qty,
                      "cps": cps,
                      "up": el.price,
                      "oc": oc,
                    }
                    this.positions.push(p);
                  }
                })
              })
            })
            }
            
          }
        }
      })

    },
    async closeFromOrder() {
      await this.loadOrders();
      var Orders = this.$store.getters["orders/getAllOrders"];
      console.log("OrdersFrom Getter:",Orders);

      Orders.forEach((order) => {
        console.log("order for", order.orderLegCollection[0].instrument.underlyingSymbol);
        if (order.orderLegCollection[0].instrument.underlyingSymbol === this.ticker) {
          order.orderLegCollection.forEach((leg) => {
            if (leg.instruction === "SELL_TO_CLOSE" || leg.instruction === "BUY_TO_CLOSE") {
              order.orderActivityCollection.forEach((legExec) => {
                legExec.executionLegs.forEach((el) => {
                  if (leg.legId === el.legId) {
                    var oc = "C";
                    var cps = "C";
                    if (leg.instrument.putCall === "PUT") {
                      cps = "P";
                    }
                    var qty = leg.quantity * 100;
                    if (leg.instruction === "SELL_TO_CLOSE") {
                      qty = qty * -1;
                    }
                    var sp = this.getSPFromSymbol(leg.instrument.symbol);
                    var xd = this.getXDFromSymbol(leg.instrument.symbol);
                    var p = {
                      "sp": sp,
                      "xd": xd,
                      "xp": qty,
                      "cps": cps,
                      "up": el.price,
                      "oc": oc,
                    }
                    this.positions.push(p);
                  }
                })
              })          
            }

            })
        }
        
      })

    },
    getSPFromSymbol(symbol) {
      var w2 = symbol.split("_")[1];
      var sp = w2.split(/[CP]/)[1];
      return sp;
    },
    getXDFromSymbol(symbol) {
      var w2 = symbol.split("_")[1];
      var xd1 = w2.split(/[CP]/)[0];
      var xd = "20" + xd1.substr(4,2) + "-" + 
                xd1.substr(0,2) + "-" + xd1.substr(2,2);
      return xd;
    },
    async loadOrders() {
      this.isLoading = true;
      console.log("LoadOrders:lookBack:",this.$store.getters["misc/getOrderLookBack"] );
      if (!this.$store.getters["orders/hasOrders"]) {
        try {
          await this.$store.dispatch("orders/setOrders");
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
      this.isLoading = false;
    },
    tradenamebuilder() {
      this.tradename = this.tn_ticker.toUpperCase() + "-" +
                        this.tn_type + "-" +
                        this.tn_cp + "-" +
                        this.tn_date + "-" +
                        this.tn_ser;
      this.ticker = this.tn_ticker.toUpperCase();
    },
    exportPortfolio() {
      var port = [];
      this.positions.forEach((pos) => {
        var p = {
          sp: pos.sp,
          xd: pos.xd,
          xp: pos.xp,
          up: pos.up,
          oc: pos.oc,
          cps: pos.cps,
        };
        port.push(p);
      });
      console.log("Exporting portfolio!");
      console.log(JSON.stringify(port));
    },
    showAnalysisView() {
      this.showAnalysisFlag = !this.showAnalysisFlag;
    },
    showParmsDialog() {
      this.showParmsDlgFlag = true;
    },
    dispLot() {
      this.showLot = true;
    },
    closeLotDlg() {
      this.showLot = false;
    },
    addLot() {
      var pos1 = this.positions[this.positions.length - 1];
      var pos2 = this.positions[this.positions.length - 2];

      var nsp1 = Math.trunc(pos1.sp) + "." + this.lotNbr;
      var nsp2 = Math.trunc(pos2.sp) + "." + this.lotNbr;
      console.log(nsp1, nsp2);
      var npos1 = {
        sp: nsp1,
        xd: pos1.xd,
        xp: this.lotSize * -1,
        up: 1,
        oc: "O",
        cps: pos1.cps,
      };
      var npos2 = {
        sp: nsp2,
        xd: pos2.xd,
        xp: this.lotSize,
        up: 1,
        oc: "O",
        cps: pos2.cps,
      };
      this.positions.push(npos2);
      this.positions.push(npos1);
      this.showLot = false;
    },
    async splitLot() {
      var newPositions = [];
      var lsLong = 0;
      var lsShort = 0;
      for (var i = this.positions.length - 1; i >= 0; i--) {
        if (Number(this.positions[i].xp) < 0 && lsShort < this.lotSize) {
          newPositions.push(this.positions[i]);
          lsShort += Math.abs(Number(this.positions[i].xp));
        }
        if (Number(this.positions[i].xp) > 0 && lsLong < this.lotSize) {
          newPositions.push(this.positions[i]);
          lsLong += Number(this.positions[i].xp);
        }
      }
      console.log(newPositions);

      this.isSaving = true;

      let today = new Date();
      var ele = this.tradename.split("-");
      const formData = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.newTradename,
        hvol: this.hvol,
        port: newPositions,
        ticker: ele[0],
      };

      await this.$store.dispatch("portfolio/registerPortfolio", formData);
      this.isSaving = false;

      this.showLot = false;
    },
    showNoteDialog() {
      this.note = "";
      this.showNoteDlgFlag = true;
    },
    closeParmsDlg() {
      this.showParmsDlgFlag = false;
    },
    setParms() {
      this.showParmsDlgFlag = false;
    },
    handleError() {
      this.error = null;
    },
    async calcGreeks() {
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      var ele = this.tradename.split("-");
      await this.getMarketPrice(token, ele[0]);
      await this.getHVol(ele[0]);
      this.positions.forEach((ele) => {
        if (ele.cps === "C" || ele.cps === "P") {
          var d2e = this.dateCalculator(ele.xd);
          var dd = d2e + 1;
          if (dd <= 0) dd = 0;
          if (dd > 0) {
            var options = this.optionCalculator(
              this.mp,
              Number(ele.sp),
              1,
              dd,
              this.hvol
            );
            ele.delta = options[2];
            ele.gamma = options[3];
            ele.vega = options[4];
            ele.theta = options[5];
          }
        } else {
          ele.delta = 0;
          ele.gamma = 0;
          ele.vega = 0;
          ele.theta = 0;
        }
      });
      console.log(this.positions);
      this.$forceUpdate();
    },
    async getMarketPrice(token, ticker) {
      console.log("Get Market Price: " + ticker);
      this.getQuote(
        token,
        ticker.toUpperCase(),
        this.getNextFrday(),
        this.getNextFrday()
      ).then((json) => {
        this.mp = json.underlyingPrice;
        console.log("Market Price: " + this.mp);
      });
      return null;
    },
    async getHVol(ticker) {
      console.log("get Hvol");
      await this.$store.dispatch("hvol/getHvol", {
        ticker: ticker.toUpperCase(),
      });
      this.$store.getters["hvol/getHvol"].find((ele) => {
        if (ele.ticker === ticker.toUpperCase()) {
          this.hvol = ele.hv100;
        }
      });
      console.log(this.hvol);
      return null;
    },
    getNextFrday() {
      var date = new Date();
      var resultDate = new Date();
      resultDate.setDate(date.getDate() + ((7 + 5 - date.getDay()) % 7));
      return resultDate.toISOString().split("T")[0];
    },
    optionCalculator(mP, sP, rate, tm, vol) {
      console.log(mP + "-" + sP + "-" + rate + "-" + tm + "-" + vol);
      var d1 = 0.0;
      var d2 = 0.0;
      var f1, f2, f3;

      rate = rate / 100.0;
      vol = vol / 100.0;
      tm = tm / 253;

      f1 = Math.pow(vol, 2.0);
      f2 = rate + f1 / 2;
      f2 = f2 * tm;
      f3 = Math.pow(tm, 0.5);

      d1 = Math.log(mP / sP) + f2;
      d1 = d1 / (vol * f3);
      d2 = d1 - vol * f3;

      var delta = this.getNormal(d1);
      var nd1 = 0.0;
      nd1 = this.getNormalDash(d1);
      var gamma = nd1 / (mP * vol * f3);
      var vega = (mP * f3 * nd1) / 100;
      var theta =
        (rate * sP * Math.exp(-1 * rate * tm) * this.getNormal(d1 - vol * f3) +
          (mP * vol * nd1) / (2 * f3)) /
        253;

      var C =
        mP * this.getNormal(d1) -
        sP * Math.exp(-1 * rate * tm) * this.getNormal(d2);
      var P = sP * Math.exp(-1 * rate * tm) - mP + C;
      console.log("Call-" + C);
      console.log("Put-" + P);
      C = this.myRound(C);
      P = this.myRound(P);
      delta = this.myRound(delta);
      gamma = this.myRound(gamma);
      vega = this.myRound(vega);
      theta = this.myRound(theta);
      var opts = new Array(C, P, delta, gamma, vega, theta);

      console.log("Returning - " + opts);
      return opts;
    },
    myRound(x) {
      x = Math.round(x * 100, 2) / 100;
      return x;
    },
    getNormal(z) {
      var c1 = 2.506628; // sq-root(2 * pi)
      var c2 = 0.3193815;
      var c3 = -0.3565638;
      var c4 = 1.7814779;
      var c5 = -1.821256;
      var c6 = 1.3302744;
      var w = 0;
      var y = 0;
      var N = 0;

      if (z >= 0) w = 1;
      else w = -1;

      y = 1 / (1 + 0.231649 * w * z);
      N =
        0.5 +
        w *
          (0.5 -
            (Math.exp((-1 * z * z) / 2) / c1) *
              (y * (c2 + y * (c3 + y * (c4 + y * (c5 + y * c6))))));
      return N;
    },
    getNormalDash(z) {
      var Nd = 0.0;
      var c1 = 2.506628; // sq-root(2 * pi)
      Nd = Math.exp((-1 * z * z) / 2) / c1;
      return Nd;
    },
    dateCalculator(ed) {
      console.log(ed);
      var numDays = 0;

      var curDate = new Date();
      var toDate = Date.parse(ed);
      //console.log(curDate);
      while (curDate <= toDate) {
        //console.log(curDate);
        var dayOfWeek = curDate.getDay();
        if (!(dayOfWeek == 6 || dayOfWeek == 0)) numDays++;
        curDate.setDate(curDate.getDate() + 1);
      }

      console.log("Returning Number of Days to Expiration- " + numDays);
      return numDays;
    },
    addPosition() {

      if (typeof this.tradetype !== "undefined") {
        var xp = 100;
        if (this.tradetype === "BF") {
          xp = 500;
        } else if (this.tradetype === "DS") {
            xp = 500;
        } else if (this.tradetype === "CS") {
          xp = 500;
        }
      }
      var dt = new Date().toISOString().substr(0, 10);

      var pos = {
        sp: "0",
        xd: dt,
        xp: xp,
        cps: this.tn_cp,
        up: "1",
        oc: "O",
        delta: null,
        gamma: null,
        theta: null,
        vega: null,
      };
      this.positions.push(pos);
      console.log("added a position");
    },
    deleteItem(i) {
      this.positions.splice(i, 1);
    },
    openImportDialog() {
      var port = [];
      this.positions.forEach((pos) => {
        var p = {
          sp: pos.sp,
          xd: pos.xd,
          xp: pos.xp,
          up: pos.up,
          oc: pos.oc,
          cps: pos.cps,
        };
        port.push(p);
      });
      this.json = JSON.stringify(port);
      this.showImpDialog = true;
    },
    postToLedger() {
      this.qtr = this.$store.getters["misc/getQtr"];
      this.showLedgerPostDialog = true;
    },
    async addNote() {
      if (JSON.stringify(this.notes) == "[]") {
        this.notes = [];
        console.log("Initialized the NOTE object and array!!");
      }
      this.notes.push(this.note);

      try {
        await this.$store.dispatch("notes/addNote", {
          tradename: this.tn,
          text: this.notes,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.notes = this.$store.getters["notes/getNotes"];
      this.showNoteDlgFlag = false;
    },
    closeNoteDialog() {
      this.showNoteDlgFlag = false;
    },
    closeImportDialog() {
      this.showImpDialog = false;
    },
    closePost2LedgerDialog() {
      this.showLedgerPostDialog = false;
    },
    portClose(port, p) {
      console.log("Port,Positions",port,p);
      for (var i = 0; i < p.length; i++) {
        if (
          p[i].cps === port.cps &&
          Number(p[i].sp) === Number(port.sp) &&
          p[i].xd === port.xd
        ) {
          if (Number(p[i].xp) + Number(port.xp) === 0) {
            return i;
          }
        }
      }
      return -1;
    },
    genClose() {
      var portfolio = this.positions;
      var portMatch = [];

      portfolio.forEach((port, idx, portfolio) => {
        portMatch[idx] = this.portClose(port, portfolio);
      });
      console.log(portMatch);
      portMatch.forEach((ele, idx) => {
        if (ele === -1) {
          var pos = {
            sp: portfolio[idx].sp,
            xd: portfolio[idx].xd,
            xp: Number(portfolio[idx].xp) * -1,
            cps: portfolio[idx].cps,
            up: 1,
            oc: "C",
            delta: null,
            gamma: null,
            theta: null,
            vega: null,
          };
          this.positions.push(pos);
        }
      });
    },
    importForm() {
      var myPortfolio = JSON.parse(this.json);

      var col = ["sp", "xd", "xp", "cps", "up", "oc"];
      for (var i = 0; i < myPortfolio.length; i++) {
        var sp = Number(myPortfolio[i][col[0]]);
        var xd = myPortfolio[i][col[1]];
        var xp = Number(myPortfolio[i][col[2]]);
        var cps = myPortfolio[i][col[3]];
        var up = Number(myPortfolio[i][col[4]]);
        var oc = myPortfolio[i][col[5]];

        var pos = {
          sp: sp,
          xd: xd,
          xp: xp,
          cps: cps,
          up: up,
          oc: oc,
          delta: null,
          gamma: null,
          theta: null,
          vega: null,
        };
        this.positions.push(pos);
      }
      this.showImpDialog = false;
    },
    async post2Ledger() {
      this.isSaving = true;

      let today = new Date();
      var ele = this.tradename.split("-");
      const formData = {
        closedate: today.toISOString().split("T")[0],
        opendate: today.toISOString().split("T")[0],
        status: "Closed",
        tradename: this.tradename,
        amount: this.pnl,
        margin: this.margin,
        capital: this.capital,
        qtr: this.qtr,
        ticker: ele[0],
      };
      await this.$store.dispatch("pnl/postPnl", formData);
      this.isSaving = false;
      this.showLedgerPostDialog = false;
    },
    async savePortfolio() {
      var port = [];
      this.isSaving = true;
      this.positions.forEach((pos) => {
        var p = {
          sp: pos.sp,
          xd: pos.xd,
          xp: pos.xp,
          up: pos.up,
          oc: pos.oc,
          cps: pos.cps,
          symbol: pos.symbol,
        };
        port.push(p);
      });

      let today = new Date();
      var ele = this.tradename.split("-");
      const formData = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.tradename,
        hvol: this.hvol,
        port: port,
        ticker: ele[0],
      };

      await this.$store.dispatch("portfolio/registerPortfolio", formData);
      this.isSaving = false;
    },
  },
  async created() {
    var trade_dt = new Date();
    this.tn_date = trade_dt
      .toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-");
    if (!this.$store.getters["portfolio/hasPortfolios"]) {
      try {
        await this.$store.dispatch("portfolio/setPortfolios");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    }
    if (typeof this.tn !== "undefined") {
      var port = this.$store.getters["portfolio/getAllPortfolios"].find(
        (port) => port.tradename === this.tn
      );
      this.tradename = port.tradename;
      this.positions = port.port;
      this.hvol = port.hvol;
      this.ticker = port.ticker;
    }
    if (typeof this.tn !== "undefined") {
      try {
        await this.$store.dispatch("notes/getNotes", {
          tradename: this.tn,
        });
        this.notes = this.$store.getters["notes/getNotes"];
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    }
  },
};
</script>

<style scoped>
.json-popup {
  position: relative;
  text-align: center;
  width: 40%;
  opacity: 1;
}
.form-popup {
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translate(-45%, 5%);
  border: 2px solid rgb(100, 24, 24);
  z-index: 9;
  opacity: 1;
  padding: 10px 10px;
  background-color: rgb(233, 241, 234);
}
.form-container {
  opacity: 1;
}
table {
  border: none;
  border-collapse: collapse;
  border-color: rgba(121, 58, 6, 0.459);
  table-layout: fixed;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-collapse: collapse;
  border-color: rgba(7, 109, 29, 0.459);
}

th.T4 {
  width: 41px;
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}
th.T5 {
  width: 40px;
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}
th.T6 {
  width: 61px;
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}
th.T15 {
  width: 132px;
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}
th.T10 {
  width: 100px;
  padding: 0px 0px;
}

td.T4 {
  width: 40px;
  padding: 0px 0px;
}
td.T6 {
  width: 60px;
  padding: 0px 0px;
}
td.T15 {
  width: 130px;
  padding: 0px 0px;
}
td.T10 {
  width: 40px;
  padding: 0px 0px;
}

input.T4 {
  width: 46px;
  height: 26px;
  padding: 0px 0px;
}
input.T6 {
  width: 68px;
  height: 26px;
  padding: 0px 0px;
}
input.T15 {
  width: 148px;
  height: 26px;
  padding: 0px 0px;
}
input.T10 {
  width: 100px;
  height: 26px;
  padding: 0px 0px;
}

label.T4 {
  width: 45.4px;
  padding: 0px 0px;
}

label.T6 {
  width: 68px;
  padding: 0px 0px;
}

label.T30 {
  width: 300px;
  padding-top: 20px;
  color: green;
  font-weight: bold;
}
h4 {
  padding: 0px 0px;
  color: green;
}
td,
th {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-collapse: collapse;
  border-color: rgba(7, 109, 29, 0.459);
}

input,
label {
  width: 40px;
  border: none;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1px;
}

input.l50 {
  width: 100%;
  border: 1px solid;
  text-align: left;
}

label {
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-bottom: 0.5rem;
}

p.profit {
  font-weight: bold;
  color: green;
}
p.loss {
  font-weight: bold;
  color: rgb(204, 35, 35);
}

p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
</style>