export default {
    registerProspect(state, payload) {
        state.prospects.push(payload);
    },
    setProspects(state, payload) {
        state.prospects = payload;
    },
    updateProspect(state, payload) {
        var found = false;
        state.prospects.forEach( (pros) => {
            if (pros.name === payload.name) {
                pros.owner = payload.owner;
                pros.ticker = payload.ticker;
                pros.erdate = payload.erdate;
                pros.status = payload.status;
                pros.qtr = payload.qtr;
                pros.ba = payload.ba;
                found = true
            }
        })
        if (!found) {
            state.prospects.push(payload);
        }  
    },
    removeProspectByName(state,payload) {
		var index = state.prospects.findIndex((prospect) => {
            return prospect.name == payload.name
        });
		if (index !== -1) {
			state.prospects.splice(index, 1);
		} else {
            console.log('Could not find prospect to remove by name', payload.name);
        }       
    },
};