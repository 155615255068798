export default {
    async addNote(context, data) {

        const token = context.rootGetters.token;
        const noteData = {
            owner: token,
            tradename: data.tradename,
            text: data.text,
        }
        const NoteData = {
            note: noteData
        };

        console.log(NoteData);

        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/note',
            {
                "headers": {
                    "Content-Type": "text/plain"
                },
                method: 'PUT',
                body: JSON.stringify(NoteData)
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to update!');
            throw error;
        }
        context.commit('addNote', data.text);
    },
    async getNotes(context,data) {

        const token = context.rootGetters.token;
        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/note',
            {
                method: 'POST',
                "headers": {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({ owner: token, tradename: data.tradename })
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) { 
            const error = new Error(responseData.message || 'Failed to fetch!');
            throw error;
        }
 
        var Note = [];
        if (responseData.data.Items.length > 0) {
            Note = responseData.data.Items[0].text;
        }
        context.commit('setNotes', Note);
    },
};
