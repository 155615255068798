<template>
  <tr v-bind:class="{'portrow':true, 'active':(status === 'Open')}">
    <td>{{ qtr }}</td>
    <td>{{ getFormattedDate }}</td>
    <td>{{ ba }}</td>
    <td>{{ ticker }}</td>
    <td>{{ name }}</td>
    <td>{{ status }}</td>
    <td>
      <base-button mode="flat" @click.native="createTrade">Trade</base-button>
      <base-button mode="flat" link :to="powerTradeLink">PowerTrade</base-button>
    </td>
    <td><base-button mode="flat" @click.native="deleteProspect">Delete</base-button></td>
  </tr>
</template>

<script>
import myMixins from "../mixins/GetQuote.js";
export default {
  props: ["qtr", "erdate", "ba", "ticker", "name", "status"],
  emits: ["notdatoken", "notrade", "save2db","delprospect"],
  mixins: [myMixins],
  data() {
    return {
      hvol: 0,
      mp: 0,
      calls: [],
      puts: [],
      isSaving: false,
    };
  },
  computed: {
    getFormattedDate() {
      return this.erdate;
    },
    powerTradeLink() {
      return "/pt/" + this.ticker;
    },        
  },
  methods: {

    async deleteProspect() {
      this.$emit("delprospect", {
        qtr: this.qtr,
        ticker: this.ticker,
        erdate: this.erdate,
        ba: this.ba,
        name: this.name,
        status: "Closed",        
      })
    },
    getNextFrday() {
      var date = new Date();
      var resultDate = new Date();
      if (resultDate.getDay() === 5) {
        resultDate.setDate(date.getDate() + 7);
      } else {
        resultDate.setDate(date.getDate() + ((7 + 5 - date.getDay()) % 7));
      }
      var formattedDate = resultDate
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      return formattedDate;
    },
    async createTrade() {
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.$emit("notdatoken", { message: "TDA token not provided" });
        return;
      }
      await this.getMarketPrice(token, this.ticker.toUpperCase());
      await this.getHVol(this.ticker.toUpperCase());
      var spShortCall = this.getStrike(this.calls,this.mp);
      var spShortPut = this.getStrike(this.puts,this.mp);

      if (spShortCall.sp === 0 || spShortPut.sp === 0) {
        this.$emit("notrade", {
          message: "No options available",
        });
        return;
      }
      var callSpread = this.getSpread(this.calls, spShortCall.sp);
      var putSpread = this.getSpread(this.puts, spShortPut.sp);
      console.log("Call/Put Spread(short):", callSpread,putSpread);
      // if (callSpread > 3 || putSpread > 3) {
      //   this.$emit("notrade", {
      //     message: "Spread too wide at ATM strike for call or put",
      //   });
      //   return;
      // }
      var callPrice = this.getOptPrice(this.calls, spShortCall.sp);
      var putPrice = this.getOptPrice(this.puts, spShortPut.sp);

      console.log("short call price:", callPrice);
      console.log("short put price: ", putPrice);
      var spCallLong = this.getStrike(this.calls,Number(spShortCall.sp) + callPrice + putPrice);
      var spPutLong = this.getStrike(this.puts,Number(spShortPut.sp) - callPrice - putPrice);
      var callSpreadLong = this.getSpread(this.calls, spCallLong.sp);
      var putSpreadLong = this.getSpread(this.puts, spPutLong.sp);
      console.log("Call/Put Spread(long):", callSpreadLong,putSpreadLong);
      // if (callSpreadLong > 4 || putSpreadLong > 4) {
      //   this.$emit("notrade", {
      //     message: "Spread too wide at OTM strike for call or put",
      //   });
      //   return;
      // }
      var callPriceLong = this.getOptPrice(this.calls, spCallLong.sp);
      var putPriceLong = this.getOptPrice(this.puts, spPutLong.sp);

      var netIC = callPrice + putPrice - callPriceLong - putPriceLong;
      var callWidth = spCallLong.sp - spShortCall.sp;
      var putWidth = spShortPut.sp - spPutLong.sp;
      console.log("Net from IC: ", netIC);
      console.log("call width:", callWidth);
      console.log("Put width:", putWidth);
      // if (netIC / callWidth < 0.6 || netIC / putWidth < 0.6) {
      //   this.$emit("notrade", {
      //     message: "Margin of safety requirement of 60% not met!",
      //   });
      //   return;
      // }
      this.savePortfolio([
        {
          sp: spShortCall.sp,
          xd: this.getNextFrday(),
          xp: -100,
          up: callPrice,
          oc: "O",
          cps: "C",
          symbol: spShortCall.symbol
        },
        {
          sp: spShortPut.sp,
          xd: this.getNextFrday(),
          xp: -100,
          up: putPrice,
          oc: "O",
          cps: "P",
          symbol: spShortPut.symbol
        },
        {
          sp: spCallLong.sp,
          xd: this.getNextFrday(),
          xp: 100,
          up: callPriceLong,
          oc: "O",
          cps: "C",
          symbol: spCallLong.symbol
        },
        {
          sp: spPutLong.sp,
          xd: this.getNextFrday(),
          xp: 100,
          up: putPriceLong,
          oc: "O",
          cps: "P",
          symbol: spPutLong.symbol
        },
      ]);
    },
    getStrike(series,mp) {
      var sp = 0;
      var symbol = null;
      console.log("Market Price:", mp);
      series.forEach((ele, i) => {
        var delta = Number(mp) - Number(ele.sp);
        if (delta < 0 && sp == 0) {
          if (Math.abs(delta) < Math.abs(Number(series[i - 1].sp) - mp)) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return {sp: sp,symbol:symbol};
    },
    getSpread(series, sp) {
      var spread = 0;
      series.forEach((ele) => {
        if (Number(ele.sp) === Number(sp) && spread === 0) {
          spread = Number(ele.ask) - Number(ele.bid);
          return;
        }
      });
      return spread;
    },
    getOptPrice(series, sp) {
      var price = 0;
      series.forEach((ele) => {
        //console.log(ele.sp,ele.ask,ele.bid);
        if (Number(ele.sp) === Number(sp) && price === 0) {
          price = (Number(ele.ask) + Number(ele.bid)) / 2;
          return;
        }
      });
      price = Math.round(price * 100, 2) / 100;
      return price;
    },
    async savePortfolio(positions) {
      var port = [];
      positions.forEach((pos) => {
        var p = {
          sp: pos.sp,
          xd: pos.xd,
          xp: pos.xp,
          up: pos.up,
          oc: pos.oc,
          cps: pos.cps,
          symbol: pos.symbol
        };
        port.push(p);
      });

      let today = new Date();

      const formData = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.ticker + "-er-" + this.erdate,
        hvol: this.hvol,
        port: port,
        ticker: this.ticker,
      };

      this.$emit("save2db", formData);      
    },
    async getMarketPrice(token, ticker) {
      console.log("Get Market Price: " + ticker);
      var json = await this.getQuote(
        token,
        ticker.toUpperCase(),
        this.getNextFrday(),
        this.getNextFrday()
      );
      this.mp = json.underlyingPrice;
      console.log("Market Price: " + this.mp);

      var callChain = json.callExpDateMap;
      for (const xp in callChain) {
        for (const sp in callChain[xp]) {
          this.calls.push({
            sp: sp,
            bid: callChain[xp][sp][0]["bid"],
            ask: callChain[xp][sp][0]["ask"],
            symbol: callChain[xp][sp][0]["symbol"],
            openInterest: callChain[xp][sp][0]["openInterest"]
          });
        }
      }
      var putChain = json.putExpDateMap;
      for (const xp in putChain) {
        for (const sp in putChain[xp]) {
          this.puts.push({
            sp: sp,
            bid: putChain[xp][sp][0]["bid"],
            ask: putChain[xp][sp][0]["ask"],
            symbol: putChain[xp][sp][0]["symbol"],
            openInterest: putChain[xp][sp][0]["openInterest"]
          });
        }
      }
      console.log(json);
    },
    async getHVol(ticker) {
      console.log("get Hvol");
      await this.$store.dispatch("hvol/getHvol", {
        ticker: ticker.toUpperCase(),
      });
      if (this.$store.getters["hvol/getHvol"].length > 0) {
        this.$store.getters["hvol/getHvol"].forEach((ele) => {
          console.log("ele:",ele);
          if (ele.ticker === ticker.toUpperCase()) {
            this.hvol = ele.hv100;
          }
        });
      } else {
        this.hvol = 30;
      }

      console.log(this.hvol);
      return null;
    },
  },
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.5rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.portrow {
  background: rgb(211, 210, 149);
}
.portrow.active {
 background: rgb(255,255,255);
}

td {
  border: 1px solid;
  border-color: rgba(7, 109, 29, 0.459);
  font-size: 12px;
}
</style>