export default {
    async registerPortfolio(context, data) {

        const token = context.rootGetters.token;
        const portData = {
            owner: token,
            updated: data.updated,
            active: data.active,
            tradename: data.tradename,
            hvol: data.hvol,
            ticker: data.ticker,
            port: data.port,
        }
        const PortfolioData = {
            portfolio: portData
        };

        console.log(PortfolioData);

        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/portfolios',
            {
                "headers": {
                    "Content-Type": "text/plain"
                },
                method: 'PUT',
                body: JSON.stringify(PortfolioData)
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to update!');
            throw error;
        }
        context.commit('updatePortfolio', {
            ...portData
        });
    },
    async setPortfolios(context) {

        const token = context.rootGetters.token;
        const response = await fetch(
            'https://t4jqg0b92b.execute-api.us-east-1.amazonaws.com/PROD/portfolios',
            {
                method: 'POST',
                "headers": {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({ owner: token })
            }
        );
        const responseData = await response.json();
        console.log(responseData);
        if (!response.ok) {
            const error = new Error(responseData.message || 'Failed to fetch!');
            throw error;
        }

        const Portfolios = [];

        for (var i = 0; i < responseData.data.Items.length; i++) {
            Portfolios.push(responseData.data.Items[i]);
        }
        context.commit('setPortfolios', Portfolios);
    },
};
