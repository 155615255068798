import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      hvol: []
    };
  },
  mutations,
  actions,
  getters
};

// {
//   owner: null,
//   ticker: null,
//   curiv: null,
//   hv100:null,
//   hv50: null,
//   hv20: null,
//   hvr: null
// }