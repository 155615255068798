export default {
    setPnLs(state, payload) {
        state.pnls = payload;
    },
    updatePnl(state, data) {
        var found = false;
        state.pnls.forEach( (pnl) => {
            if (pnl.tradename === data.tradename) {
                pnl.owner = data.owner;
                pnl.ticker = data.ticker;
                pnl.opendate=  data.opendate,
                pnl.closedate = data.closedate,
                pnl.amount = data.amount,
                pnl.capital = data.capital,
                pnl.margin = data.margin,
                pnl.status = data.status,
                pnl.qtr = data.qtr
                found = true
            }
        })
        if (!found) {
            state.pnls.push(data);
        }  
    },
};