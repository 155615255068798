import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OptionCalc from '../components/OptionCalc.vue'
import D2e from '../components/D2e.vue'
import Prospects from '../components/ProspectList.vue'
import ProspectForm from '../components/ProspectForm.vue'
import PnLForm from '../components/PnL.vue'
import COEForm from '../components/COEForm.vue'
import PowerTrade from '../components/PowerTrade.vue'
import HVolForm from '../components/HVolForm.vue'
import Portfolios from '../components/PortfolioList.vue'
import PortfolioForm from '../components/PortfolioForm.vue'
import SpreadCalc from '../components/SpreadCalc.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue')
  },
  {
    path: '/option',
    name: 'Option',
    component: OptionCalc
  },
  {
    path: '/spread',
    name: 'Spread',
    component: SpreadCalc
  },
  {
    path: '/d2e',
    name: 'D2E',
    component: D2e    
  },
  {
    path: '/prospect',
    name: 'Prospects',
    component: Prospects    
  },
  {
    path: '/addprospect',
    name: 'AddProspects',
    component: ProspectForm
  },
  {
    path: '/pnl',
    name: 'PnL',
    component: PnLForm
  }, 
  {
    path: '/coe',
    name: 'COE',
    component: COEForm
  }, 
  {
    path: '/pt/:tck',
    name: 'PowerTrade',
    component: PowerTrade,
    props: true,
  }, 
  {
    path: '/hvol',
    name: 'HVol',
    component: HVolForm
  }, 
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolios
  }, 
  {
    path: '/addportfolio',
    name: 'AddPortfolio',
    component: PortfolioForm
  },
  {
    path: '/viewportfolio/:tn',
    component: PortfolioForm,
    name: 'ViewPortfolio',
    props: true,
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
