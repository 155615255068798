import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      prospects: []
    };
  },
  mutations,
  actions,
  getters
};

// {
//   owner: null,
//   ticker: null,
//   erdate: null,
//   status: null,
//   qtr: null,
//   ba: null,
//   name: null
// }