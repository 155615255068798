export default {
    token(state) {
      return state.token;
    },
    tdatoken(state) {
      return state.tdatoken;
    },
    tdacct(state) {
      return state.tdacct;
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    didAutoLogout(state) {
      return state.didAutoLogout;
    }
  };