<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-card>
      <p>Select your trade type</p>
      <label for="ic" class="ilb">Iron Condor</label>
      <input
        class="ilb"
        type="radio"
        value="ic"
        id="ic"
        name="tradetype"
        v-model="tradetype"
      />
      <label for="ds" class="ilb">Debit spread</label>
      <input
        class="ilb"
        type="radio"
        value="ds"
        id="ds"
        name="tradetype"
        v-model="tradetype"
      />
      <label for="bf" class="ilb">Butterfly</label>
      <input
        class="ilb"
        type="radio"
        value="bf"
        id="bf"
        name="tradetype"
        v-model="tradetype"
      />
    </base-card>
    <base-card v-if="tradetype === 'ic'">
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p>Iron Condor Setup</p>
      <div>
        <label for="ticker">Ticker</label>
        <input id="ticker" type="text" v-model="ticker" class="l50" />
        <label for="tradename">Name</label>
        <input id="tradename" type="text" v-model="tradename" class="l50" />
        <label for="strike">Strike</label>
        <input id="strike" type="text" v-model="strike" class="l50" />
        <label for="spread">Spread</label>
        <input id="spread" type="text" v-model="spread" class="l50" />
        <br />
        <base-button @click.native="shopIc">Shop</base-button>
        <br />
        <label for="netcredit">Net Credit</label>
        <input id="netcredit" type="text" v-model="netcredit" class="l50" />
        <label for="netrevs">Net revenue</label>
        <input id="netrevs" type="text" v-model="netrevs" class="l50" />
        <label for="netcost">Net cost</label>
        <input id="netcost" type="text" v-model="netcost" class="l50" />

        <label for="numcontracts">Number of contracts</label>
        <input
          id="numcontracts"
          type="text"
          v-model="numcontracts"
          class="l50"
        />

        <br />
        <base-button @click.native="placeICTrade">Trade</base-button>
        <br />
        <p>Max-loss: {{ maxLoss }}</p>
        <p>Coverage: {{ coverage }}</p>
      </div>
    </base-card>
    <base-card v-if="tradetype === 'bf'">
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p>Butterfly Setup</p>
      <div>
        <label for="ticker">Ticker</label>
        <input id="ticker" type="text" v-model="bf_ticker" class="l50" />
        <label for="tradename">Name</label>
        <input id="tradename" type="text" v-model="bf_tradename" class="l50" />
        <label for="bfdate">Option series for (YYYY-mm-dd)</label>
        <input id="bfdate" type="text" v-model="bf_date" class="l50" />
        <p>Select your option type</p>
        <label for="call" class="ilb">Calls</label>
        <input
          class="ilb"
          type="radio"
          value="C"
          id="call"
          name="bftype"
          v-model="bf_type"
        />
        <label for="put" class="ilb">Puts</label>
        <input
          class="ilb"
          type="radio"
          value="P"
          id="put"
          name="bftype"
          v-model="bf_type"
        />
        <table>
          <thead>
            <tr>
              <th class="T4"></th>              
              <th class="T4">Short </th>
              <th class="T4">Long-1</th>
              <th class="T4">Long-2</th>
            </tr>
          </thead>       
          <tr>
              <th class="T4">Strike</th>              
              <td class="T4"><input id="ss" type="text" v-model="bf_ss" class="T4" @change="popStrike" /></td>
              <td class="T4"><input id="ls1" type="text" v-model="bf_ls1" class="T4" /></td>
              <td class="T4"><input id="ls2" type="text" v-model="bf_ls2" class="T4" /></td>
          </tr>           
          <tr>
              <th class="T4">Qty</th>              
              <td class="T4"><input id="ssqty" type="text" v-model="bf_ss_qty" class="T4" /></td>
              <td class="T4"><input id="ls1qty" type="text" v-model="bf_ls1_qty" class="T4" /></td>
              <td class="T4"><input id="ls2qty" type="text" v-model="bf_ls2_qty" class="T4" /></td>
          </tr>  
        </table>                        
        <br />
        <base-button @click.native="shopBF">Shop</base-button>
        <br />
        <label for="bfnet">Net Credit</label>
        <input id="bfnet" type="text" v-model="bf_net" class="l50" />

        <br />
        <base-button @click.native="placeBFTrade">Trade</base-button>
        <br />
        <p v-if="bf_net != 0" >Max-loss: {{ bf_maxLoss }}</p>
      </div>
    </base-card>
    <base-card v-if="tradetype === 'ds'">
      <div v-if="isSaving">
        <base-spinner></base-spinner>
      </div>
      <p>Debit Spread Setup</p>
      <div>
        <label for="ticker">Ticker</label>
        <input id="ticker" type="text" v-model="ticker" class="l50" />
        <label for="tradename">Name</label>
        <input id="tradename" type="text" v-model="tradename" class="l50" />
        <label for="tradeyearmonthday">Option series after (YYYY-mm-dd)</label>
        <input
          id="tradeyearmonthday"
          type="text"
          v-model="tradeyearmonthday"
          class="l50"
        />
        <label for="delta">Target delta</label>
        <input id="delta" type="text" v-model="delta" class="l50" />
        <br />
        <base-button @click.native="shopDs">Shop</base-button>
        <br />
        <label for="netdebit">Net Debit</label>
        <input id="netdebit" type="text" v-model="netdebit" class="l50" />
        <label for="numcontracts">Number of contracts</label>
        <input
          id="numcontracts"
          type="text"
          v-model="numcontracts"
          class="l50"
        />
        <br />
        <base-button @click.native="placeDSTrade">Trade</base-button>
        <br />
        <p>Volatility spread: {{ volSpread }}</p>
      </div>
    </base-card>
  </div>
</template>
<script>
import myMixins from "../mixins/GetQuote.js";
import myMixinsCO from "../mixins/CreateOrder.js";
export default {
  mixins: [myMixins, myMixinsCO],
  data() {
    return {
      tradename: "",
      ticker: "",
      strike: 0,
      spread: 0,
      netcredit: 0,
      netrevs: 0,
      netcost: 0,
      numcontracts: 1,
      placetrade: "",
      tradetype: "",
      tradeyearmonthday: "",
      delta: 0,
      netdebit: 0,
      volSpread: 0,
      hvol: 0,
      mp: 0,
      calls: [],
      puts: [],
      port: [],
      isSaving: false,
      error: "",
      bf_ticker: "",
      bf_tradename: "",
      bf_date: "",
      bf_type: "P",
      bf_ls1: 0,
      bf_ls2: 0,
      bf_ss: 0,
      bf_ls1_qty: 500,
      bf_ls2_qty: 500,
      bf_ss_qty: -1000,
      bf_net: 0,
    };
  },
  computed: {
    maxLoss() {
      return (
        Math.round((this.spread - this.netcredit + Number.EPSILON) * 100) / 100
      );
    },
    bf_maxLoss() {
      return (
        (Math.round(
          (Math.abs(this.bf_ss - this.bf_ls2) - this.bf_net + Number.EPSILON) *
            100
        ) /
          100) *
        (Math.abs(this.bf_ss_qty) - Math.abs(this.bf_ls2_qty))
      );
    },
    coverage() {
      var vol4d = (this.hvol / Math.sqrt(252)) * 2;
      console.log("4 day vol:", vol4d);
      var tSpread =
        (Number(this.strike) * (100 + vol4d)) / 100 -
        (Number(this.strike) * (100 - vol4d)) / 100;
      console.log("tSpread:", tSpread);
      var aSpread = this.netcredit * 2;
      console.log("aSpread:", aSpread);
      var cov = (aSpread * 100) / tSpread;
      console.log("coverage:", cov);
      cov = Math.round((cov + Number.EPSILON) * 100) / 100;
      return cov;
    },
  },
  created() {
    var dt = new Date();
    dt.setDate(dt.getDate() + 90);
    this.tradeyearmonthday = dt
      .toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-");

    var bf_dt = new Date();
    this.bf_date = bf_dt
      .toLocaleDateString("pt-br")
      .split("/")
      .reverse()
      .join("-");
  },
  methods: {
    popStrike() {
      if (this.bf_type === "P") {
        this.bf_ls1 = Number(this.bf_ss) + 5;
        this.bf_ls2 = Number(this.bf_ss) - 10;
      } else {
        this.bf_ls1 = Number(this.bf_ss) - 5;
        this.bf_ls2 = Number(this.bf_ss) + 10;
      }
    },
    getNextFrday() {
      var date = new Date();
      var resultDate = new Date();
      if (resultDate.getDay() === 5) {
        resultDate.setDate(date.getDate() + 7);
      } else {
        resultDate.setDate(date.getDate() + ((7 + 5 - date.getDay()) % 7));
      }
      var formattedDate = resultDate
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      return formattedDate;
    },
    getToday() {
      var date = new Date();

      var formattedDate = date
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
      return formattedDate;
    },
    async shopBF() {
      this.isSaving = true;
      this.bf_tradename =
        this.bf_ticker + "-bwbf-" + this.bf_type + "-" + this.bf_date;
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      await this.getMarketPrice(
        token,
        this.bf_ticker.toUpperCase(),
        this.bf_date
      );

      if (this.bf_type === "P") {
        var spShortPut = this.getStrike(this.puts, this.bf_ss);
        var spLongPut1 = this.getStrike(this.puts, this.bf_ls1);
        var spLongPut2 = this.getStrike(this.puts, this.bf_ls2);
        if (spShortPut.sp === 0 || spLongPut1.sp === 0 || spLongPut2.sp === 0) {
          this.error = "No options available";
          return;
        }
        var putPriceShort = this.getOptPrice(this.puts, spShortPut.sp);
        var putPriceLong1 = this.getOptPrice(this.puts, spLongPut1.sp);
        var putPriceLong2 = this.getOptPrice(this.puts, spLongPut2.sp);
        this.bf_net = putPriceShort * 2 - putPriceLong1 - putPriceLong2;
        this.port = [
          {
            sp: spShortPut.sp,
            xd: this.bf_date,
            xp: this.bf_ss_qty,
            up: putPriceShort,
            oc: "O",
            cps: "P",
            symbol: spShortPut.symbol,
          },
          {
            sp: spLongPut1.sp,
            xd: this.bf_date,
            xp: this.bf_ls1_qty,
            up: putPriceLong1,
            oc: "O",
            cps: "P",
            symbol: spLongPut1.symbol,
          },
          {
            sp: spLongPut2.sp,
            xd: this.bf_date,
            xp: this.bf_ls2_qty,
            up: putPriceLong2,
            oc: "O",
            cps: "P",
            symbol: spLongPut2.symbol,
          },
        ];
      } else {
        var spShortCall = this.getStrike(this.calls, this.bf_ss);
        var spLongCall1 = this.getStrike(this.calls, this.bf_ls1);
        var spLongCall2 = this.getStrike(this.calls, this.bf_ls2);
        if (
          spShortCall.sp === 0 ||
          spLongCall1.sp === 0 ||
          spLongCall2.sp === 0
        ) {
          this.error = "No options available";
          return;
        }
        var callPriceShort = this.getOptPrice(this.calls, spShortCall.sp);
        var callPriceLong1 = this.getOptPrice(this.calls, spLongCall1.sp);
        var callPriceLong2 = this.getOptPrice(this.calls, spLongCall2.sp);
        this.bf_net = callPriceShort * 2 - callPriceLong1 - callPriceLong2;
        this.port = [
          {
            sp: spShortCall.sp,
            xd: this.bf_date,
            xp: this.bf_ss_qty,
            up: callPriceShort,
            oc: "O",
            cps: "C",
            symbol: spShortCall.symbol,
          },
          {
            sp: spLongCall1.sp,
            xd: this.bf_date,
            xp: this.bf_ls1_qty,
            up: callPriceLong1,
            oc: "O",
            cps: "C",
            symbol: spLongCall1.symbol,
          },
          {
            sp: spLongCall2.sp,
            xd: this.bf_date,
            xp: this.bf_ls2_qty,
            up: callPriceLong2,
            oc: "O",
            cps: "C",
            symbol: spLongCall2.symbol,
          },
        ];
      }

      this.bf_net = Math.round((this.bf_net + Number.EPSILON) * 100) / 100;

      this.isSaving = false;
    },
    async placeBFTrade() {
      this.isSaving = true;
      let today = new Date();

      const prospect = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.bf_tradename,
        hvol: 0,
        port: this.port,
        ticker: this.bf_ticker,
      };
      console.log(prospect);
      this.isLoading = true;
      await this.$store.dispatch("portfolio/registerPortfolio", prospect);
      console.log("Done creating the trade and saving in portfolio table");

      var token = this.$store.getters.tdatoken;
      var tdacct = this.$store.getters.tdacct;
      var net = this.bf_net * -1;
      if (tdacct !== null) {
        await this.createBFOrder(token, prospect, net, "Open");
        console.log("Created order on TDA - Yayyy!!");
      }

      this.isSaving = false;
    },
    async shopIc() {
      this.isSaving = true;
      this.tradename = this.ticker + "-ic-" + this.getNextFrday();
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      await this.getMarketPrice(token, this.ticker.toUpperCase(), null);
      await this.getHVol(this.ticker.toUpperCase());
      var spShortCall = this.getStrike(this.calls, this.strike);
      var spShortPut = this.getStrike(this.puts, this.strike);

      if (spShortCall.sp === 0 || spShortPut.sp === 0) {
        this.error = "No options available";
        return;
      }
      var callPrice = this.getOptPrice(this.calls, spShortCall.sp);
      var putPrice = this.getOptPrice(this.puts, spShortPut.sp);

      var spCallLong = this.getStrike(
        this.calls,
        Number(spShortCall.sp) + Number(this.spread)
      );
      var spPutLong = this.getStrike(
        this.puts,
        Number(spShortPut.sp) - this.spread
      );
      var callPriceLong = this.getOptPrice(this.calls, spCallLong.sp);
      var putPriceLong = this.getOptPrice(this.puts, spPutLong.sp);

      this.netcredit = callPrice + putPrice - callPriceLong - putPriceLong;
      this.netrevs = callPrice + putPrice;
      this.netcost = callPriceLong + putPriceLong;
      this.netcredit =
        Math.round((this.netcredit + Number.EPSILON) * 100) / 100;
      this.netrevs = Math.round((this.netrevs + Number.EPSILON) * 100) / 100;
      this.netcost = Math.round((this.netcost + Number.EPSILON) * 100) / 100;
      this.port = [
        {
          sp: spShortCall.sp,
          xd: this.getNextFrday(),
          xp: -100,
          up: callPrice,
          oc: "O",
          cps: "C",
          symbol: spShortCall.symbol,
        },
        {
          sp: spShortPut.sp,
          xd: this.getNextFrday(),
          xp: -100,
          up: putPrice,
          oc: "O",
          cps: "P",
          symbol: spShortPut.symbol,
        },
        {
          sp: spCallLong.sp,
          xd: this.getNextFrday(),
          xp: 100,
          up: callPriceLong,
          oc: "O",
          cps: "C",
          symbol: spCallLong.symbol,
        },
        {
          sp: spPutLong.sp,
          xd: this.getNextFrday(),
          xp: 100,
          up: putPriceLong,
          oc: "O",
          cps: "P",
          symbol: spPutLong.symbol,
        },
      ];
      this.isSaving = false;
    },
    getStrike(series, mp) {
      var sp = 0;
      var symbol = null;
      console.log("Market Price:", mp);
      series.forEach((ele, i) => {
        var delta = Number(mp) - Number(ele.sp);
        if (delta < 0 && sp == 0) {
          if (Math.abs(delta) < Math.abs(Number(series[i - 1].sp) - mp)) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return { sp: sp, symbol: symbol };
    },
    getOptPrice(series, sp) {
      var price = 0;
      series.forEach((ele) => {
        if (Number(ele.sp) === Number(sp) && price === 0) {
          price = (Number(ele.ask) + Number(ele.bid)) / 2;
          return;
        }
      });
      price = Math.round(price * 100, 2) / 100;
      return price;
    },
    async placeICTrade() {
      this.isSaving = true;
      let today = new Date();

      const prospect = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.tradename,
        hvol: this.hvol,
        port: this.port,
        ticker: this.ticker,
      };
      console.log(prospect);
      this.isLoading = true;
      await this.$store.dispatch("portfolio/registerPortfolio", prospect);
      console.log("Done creating the trade and saving in portfolio table");

      var token = this.$store.getters.tdatoken;
      var tdacct = this.$store.getters.tdacct;
      console.log("Placetrade flag:", this.placetrade);
      var net = this.netcredit * -1;
      if (tdacct !== null) {
        await this.createOrder(token, tdacct, prospect, net);
        console.log("Created order on TDA - Yayyy!!");
      }

      this.isSaving = false;
    },
    async getMarketPrice(token, ticker, optionDate) {
      console.log("Get Market Price: " + ticker);
      var opDate = optionDate;
      if (optionDate === "") {
        opDate = this.getNextFrday();
      }
      var tick = ticker;
      if (tick === "SPX") {
        tick = "$SPX.X";
      }
      console.log("OpDate/Ticker: " + opDate + "/" + tick);
      var json = await this.getQuote(token, tick.toUpperCase(), opDate, opDate);
      this.mp = json.underlyingPrice;
      console.log("Market Price: " + this.mp);

      this.calls = [];
      this.puts = [];
      var callChain = json.callExpDateMap;
      for (const xp in callChain) {
        for (const sp in callChain[xp]) {
          this.calls.push({
            sp: sp,
            bid: callChain[xp][sp][0]["bid"],
            ask: callChain[xp][sp][0]["ask"],
            symbol: callChain[xp][sp][0]["symbol"],
            openInterest: callChain[xp][sp][0]["openInterest"],
          });
        }
      }
      var putChain = json.putExpDateMap;
      for (const xp in putChain) {
        for (const sp in putChain[xp]) {
          this.puts.push({
            sp: sp,
            bid: putChain[xp][sp][0]["bid"],
            ask: putChain[xp][sp][0]["ask"],
            symbol: putChain[xp][sp][0]["symbol"],
            openInterest: putChain[xp][sp][0]["openInterest"],
          });
        }
      }
      console.log(json);
    },
    async getHVol(ticker) {
      console.log("get Hvol");
      await this.$store.dispatch("hvol/getHvol", {
        ticker: ticker.toUpperCase(),
      });
      if (this.$store.getters["hvol/getHvol"].length > 0) {
        this.$store.getters["hvol/getHvol"].forEach((ele) => {
          console.log("ele:", ele);
          if (ele.ticker === ticker.toUpperCase()) {
            this.hvol = ele.hv100;
          }
        });
      } else {
        this.hvol = 30;
      }

      console.log(this.hvol);
      return null;
    },
    async getOptionChain(token, ticker) {
      console.log("Get Option chain: " + ticker);
      var json = await this.getFutureQuote(
        token,
        ticker.toUpperCase(),
        this.tradeyearmonthday
      );
      this.mp = json.underlyingPrice;
      console.log("Market Price: " + this.mp);

      this.calls = [];
      this.puts = [];
      var callChain = json.callExpDateMap;
      for (const xp in callChain) {
        for (const sp in callChain[xp]) {
          this.calls.push({
            sp: sp,
            bid: callChain[xp][sp][0]["bid"],
            ask: callChain[xp][sp][0]["ask"],
            symbol: callChain[xp][sp][0]["symbol"],
            volatility: callChain[xp][sp][0]["volatility"],
            delta: callChain[xp][sp][0]["delta"],
            xd: xp.split(":")[0],
            openInterest: callChain[xp][sp][0]["openInterest"],
            up:
              (Number(callChain[xp][sp][0]["bid"]) +
                Number(callChain[xp][sp][0]["ask"])) /
              2,
          });
        }
      }
      var putChain = json.putExpDateMap;
      for (const xp in putChain) {
        for (const sp in putChain[xp]) {
          this.puts.push({
            sp: sp,
            bid: putChain[xp][sp][0]["bid"],
            ask: putChain[xp][sp][0]["ask"],
            symbol: putChain[xp][sp][0]["symbol"],
            volatility: putChain[xp][sp][0]["volatility"],
            delta: putChain[xp][sp][0]["delta"],
            xd: xp.split(":")[0],
            openInterest: putChain[xp][sp][0]["openInterest"],
            up:
              (Number(putChain[xp][sp][0]["bid"]) +
                Number(putChain[xp][sp][0]["ask"])) /
              2,
          });
        }
      }
      console.log(json);
    },
    getCallStrike(series, del) {
      var sp = 0;
      var symbol = null;
      var up = 0;
      var xd = null;
      var volatility = 0;

      series.forEach((ele, i) => {
        var delta = Number(del) - Number(ele.delta);
        if (delta > 0 && sp == 0) {
          if (Math.abs(delta) < Math.abs(Number(series[i - 1].delta) - del)) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
            up = ele.up;
            xd = ele.xd;
            volatility = ele.volatility;
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
            up = series[i - 1].up;
            xd = series[i - 1].xd;
            volatility = series[i - 1].volatility;
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return { sp: sp, symbol: symbol, up: up, xd: xd, volatility: volatility };
    },
    getPutStrike(series, del) {
      var sp = 0;
      var symbol = null;
      var up = 0;
      var xd = null;
      var volatility = 0;      

      series.forEach((ele, i) => {
        var delta = Math.abs(Number(del)) - Math.abs(Number(ele.delta));
        if (delta < 0 && sp == 0) {
          if (
            Math.abs(delta) <
            Math.abs(Math.abs(Number(series[i - 1].delta)) - del)
          ) {
            sp = Number(ele.sp);
            symbol = ele.symbol;
            up = ele.up;
            xd = ele.xd;
            volatility = ele.volatility;            
          } else {
            sp = Number(series[i - 1].sp);
            symbol = series[i - 1].symbol;
            up = series[i - 1].up;
            xd = series[i - 1].xd;
            volatility = series[i - 1].volatility;            
          }
          return;
        }
      });
      console.log("Strike:", sp);
      return { sp: sp, symbol: symbol, up: up, xd: xd, volatility: volatility };
    },        
    async shopDs() {
      this.isSaving = true;
      this.tradename = this.ticker + "-ds-" + this.getToday();
      var token = this.$store.getters.tdatoken;
      if (token === null) {
        this.error = "TDA token not provided";
        return;
      }
      await this.getOptionChain(token, this.ticker.toUpperCase());

      if (this.delta > 0) {

        var spCallLong = this.getCallStrike(this.calls, 0.2);
        if (spCallLong.sp === 0) {
          this.error = "No options available";
          return;
        }
        var spCallShort = this.getCallStrike(this.calls, 0.1);
        console.log("Long call:", spCallLong);
        console.log("Short call:", spCallShort);
        this.netdebit = spCallLong.up - spCallShort.up;
        this.netdebit =
          Math.round((this.netdebit + Number.EPSILON) * 100) / 100;
        this.volSpread =
          Math.round(
            (Number(spCallShort.volatility) -
              Number(spCallLong.volatility) +
              Number.EPSILON) *
              100
          ) / 100;
        this.port = [
          {
            sp: spCallShort.sp,
            xd: spCallShort.xd,
            xp: -100,
            up: spCallShort.up,
            oc: "O",
            cps: "C",
            symbol: spCallShort.symbol,
          },
          {
            sp: spCallLong.sp,
            xd: spCallLong.xd,
            xp: 100,
            up: spCallLong.up,
            oc: "O",
            cps: "C",
            symbol: spCallLong.symbol,
          },
        ];
      } else {
        var spPutLong = this.getPutStrike(this.puts, -0.2);
        if (spPutLong.sp === 0) {
          this.error = "No options available";
          return;
        }
        var spPutShort = this.getPutStrike(this.puts, -0.1);

        this.netdebit = spPutLong.up - spPutShort.up;
        this.netdebit =
          Math.round((this.netdebit + Number.EPSILON) * 100) / 100;
        this.volSpread =
          Math.round(
            (Number(spPutShort.volatility) -
              Number(spPutLong.volatility) +
              Number.EPSILON) *
              100
          ) / 100;

        this.port = [
          {
            sp: spPutShort.sp,
            xd: spPutShort.xd,
            xp: -100,
            up: spPutShort.up,
            oc: "O",
            cps: "P",
            symbol: spPutShort.symbol,
          },
          {
            sp: spPutLong.sp,
            xd: spPutLong.xd,
            xp: 100,
            up: spPutLong.up,
            oc: "O",
            cps: "P",
            symbol: spPutLong.symbol,
          },
        ];
      }

      this.isSaving = false;
    },
    async placeDSTrade() {
      this.isSaving = true;
      let today = new Date();

      const prospect = {
        updated: today.toISOString().split("T")[0],
        active: "Y",
        tradename: this.tradename,
        hvol: this.hvol,
        port: this.port,
        ticker: this.ticker,
      };
      console.log(prospect);
      this.isLoading = true;
      await this.$store.dispatch("portfolio/registerPortfolio", prospect);
      console.log("Done creating the trade and saving in portfolio table");

      var token = this.$store.getters.tdatoken;
      var tdacct = this.$store.getters.tdacct;
      var net = this.netdebit;
      if (tdacct !== null) {
        await this.createDSOrder(token, tdacct, prospect, net);
        console.log("Created order on TDA - Yayyy!!");
      }

      this.isSaving = false;      
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.json-popup {
  position: relative;
  text-align: center;
  width: 50%;
  opacity: 1;
}
.form-popup {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-45%, 5%);
  border: 2px solid rgb(100, 24, 24);
  z-index: 9;
  opacity: 1;
  padding: 20px 20px;
  background-color: rgb(233, 241, 234);
}
.form-container {
  opacity: 1;
}

input,
label {
  width: 100%;
  border: none;
  text-align: left;
  vertical-align: middle;
}

input.ilb {
  width: 10%;
  border: 1px solid;
  text-align: left;
  margin-bottom: 0.8rem;
  display: inline-block;
}

input.l50 {
  width: 100%;
  border: 1px solid;
  text-align: left;
  margin-bottom: 0.8rem;
}

label.ilb {
  margin-left: 20px;
  width: 15%;
  display: inline-block;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.1rem;
}

p {
  font-weight: bold;
}

table {
  border: 16px ridge(5, 10, 1);
  border-collapse: collapse;
  border-color: rgba(121, 58, 6, 0.459);
  table-layout: fixed;
  width: 100%;
}

th.T4 {
  width: 40px;
  height: 36px;
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}

td.T4 {
  width: 40px;
  height: 36px;
  padding: 0px 0px;
}

input.T4 {
  width: 120px;
  height: 36px;
  padding: 0px 0px;
}

td,
th {
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-right: 2px solid;
  border-collapse: collapse;
  border-color: rgba(7, 109, 29, 0.459);
}

</style>