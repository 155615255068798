<template>
  <div>
    <base-card>
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <form id="mainForm" @submit.prevent="submitForm">
              <div class="form-group">
                <label for="mP">Market price</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="mP"
                  v-model="mP"
                />
              </div>
              <div class="form-group">
                <label for="sP1">Long Strike</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="sP1"
                  v-model="sP1"
                />
              </div>
             <div class="form-group">
                <label for="sP2">Short Strike</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="sP2"
                  v-model="sP2"
                />
              </div>
              <div class="form-group">
                <label for="rate">Interest Rate</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="rate"
                  v-model="rate"
                />
              </div>
              <div class="form-group">
                <label for="tm">Days to Expiration</label>
                <input
                  type="number"
                  class="form-control"
                  id="tm"
                  v-model="tm"
                />
              </div>
              <div class="form-group">
                <label for="vol1">Volatility (Long)</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="vol1"
                  v-model="vol1"
                />
              </div>
              <div class="form-group">
                <label for="vol2">Volatility (Short)</label>
                <input
                  type="number"
                  step="0.01"
                  class="form-control"
                  id="vol2"
                  v-model="vol2"
                />
              </div>              
              <base-button>Compute</base-button>
              <base-card v-for="o in output" :key="o">
                <ul>
                  <li><h3>MP: {{o.mP}} | Rate: {{ o.rate }} | Time: {{ o.tm }}</h3></li>
                  <li><h3>SP-Long: {{ o.sP1 }}  | IV-Long: {{ o.vol1 }} </h3></li>
                  <li><h3>SP-Short: {{ o.sP2 }}  | IV-Short: {{ o.vol2 }} </h3></li>
                  <li>Call: {{ o.CS }} | Put: {{ o.PS }} | Delta: {{ o.DS }} | Gamma: {{ o.GS }} | Vega: {{ o.VS }} | Theta: {{ o.TS }}</li>
                </ul>
              </base-card>
            </form>
          </div>
        </div>
      </div>
    </base-card>
  </div>
</template>

<script>
import BaseButton from "./ui/BaseButton.vue";
import BaseCard from "./ui/BaseCard.vue";
export default {
  components: { BaseCard, BaseButton },
  data() {
    return {
      mP: 0,
      sP1: 0,
      sP2: 0,
      rate: 0,
      tm: 0,
      vol1: 0,
      vol2: 0,
      optionsLong: [],
      optionsShort: [],
      dispResults: false,
      output: [],
    };
  },
  computed: {
    showResults() {
      return this.dispResults;
    },
  },
  mounted() {
    this.dispResults = false;
  },
  methods: {
    optionCalculator(mP, sP, rate, tm, vol) {
      console.log(mP + "-" + sP + "-" + rate + "-" + tm + "-" + vol);
      var d1 = 0.0;
      var d2 = 0.0;
      var f1, f2, f3;

      rate = rate / 100.0;
      vol = vol / 100.0;
      tm = tm / 253;

      f1 = Math.pow(vol, 2.0);
      f2 = rate + f1 / 2;
      f2 = f2 * tm;
      f3 = Math.pow(tm, 0.5);

      d1 = Math.log(mP / sP) + f2;
      d1 = d1 / (vol * f3);
      d2 = d1 - vol * f3;

      var delta = this.getNormal(d1);
      var nd1 = 0.0;
      nd1 = this.getNormalDash(d1);
      var gamma = nd1 / (mP * vol * f3);
      var vega = (mP * f3 * nd1) / 100;
      var theta =
        (rate * sP * Math.exp(-1 * rate * tm) * this.getNormal(d1 - vol * f3) +
          (mP * vol * nd1) / (2 * f3)) /
        253;

      var C =
        mP * this.getNormal(d1) -
        sP * Math.exp(-1 * rate * tm) * this.getNormal(d2);
      var P = sP * Math.exp(-1 * rate * tm) - mP + C;
      console.log("Call-" + C);
      console.log("Put-" + P);
      C = this.myRound(C);
      P = this.myRound(P);
      delta = this.myRound(delta);
      gamma = this.myRound(gamma);
      vega = this.myRound(vega);
      theta = this.myRound(theta);
      var opts = new Array(C, P, delta, gamma, vega, theta);

      console.log("Returning - " + opts);
      return opts;
    },

    myRound(x) {
      x = Math.round(x * 100, 2) / 100;
      return x;
    },

    getNormal(z) {
      var c1 = 2.506628; // sq-root(2 * pi)
      var c2 = 0.3193815;
      var c3 = -0.3565638;
      var c4 = 1.7814779;
      var c5 = -1.821256;
      var c6 = 1.3302744;
      var w = 0;
      var y = 0;
      var N = 0;

      if (z >= 0) w = 1;
      else w = -1;

      y = 1 / (1 + 0.231649 * w * z);
      N =
        0.5 +
        w *
          (0.5 -
            (Math.exp((-1 * z * z) / 2) / c1) *
              (y * (c2 + y * (c3 + y * (c4 + y * (c5 + y * c6))))));
      return N;
    },

    getNormalDash(z) {
      var Nd = 0.0;
      var c1 = 2.506628; // sq-root(2 * pi)
      Nd = Math.exp((-1 * z * z) / 2) / c1;
      return Nd;
    },

    dateCalculator(ed) {
      console.log(ed);
      var numDays = 0;

      var curDate = new Date();
      var toDate = Date.parse(ed);
      console.log(curDate);
      while (curDate <= toDate) {
        console.log(curDate);
        var dayOfWeek = curDate.getDay();
        if (!(dayOfWeek == 6 || dayOfWeek == 0)) numDays++;
        curDate.setDate(curDate.getDate() + 1);
      }

      console.log("Returning Number of Days to Expiration- " + numDays);
      return numDays;
    },

    submitForm() {
      this.optionsLong = this.optionCalculator(
        this.mP,
        this.sP1,
        this.rate,
        this.tm,
        this.vol1
      );
      this.optionsShort = this.optionCalculator(
        this.mP,
        this.sP2,
        this.rate,
        this.tm,
        this.vol2
      );

      this.output.push({
        "mP": this.mP,
        "sP1": this.sP1,
        "sP2": this.sP2,
        "rate": this.rate,
        "tm": this.tm,
        "vol1": this.vol1,
        "vol2": this.vol2,
        CS: this.myRound(this.optionsLong[0] - this.optionsShort[0]),
        PS: this.myRound(this.optionsLong[1] - this.optionsShort[1]),
        DS: this.myRound(this.optionsLong[2] - this.optionsShort[2]),
        GS: this.myRound(this.optionsLong[3] - this.optionsShort[3]),
        VS: this.myRound(this.optionsLong[4] - this.optionsShort[4]),
        TS: this.myRound(this.optionsLong[5] - this.optionsShort[5]),
      });

      this.dispResults = true;
    },
  },
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}

input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

ul {
  list-style: none;
}

li {
  font-size: 13px;
  font-weight: bold;
}
.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>