export default {
    registerPortfolio(state, payload) {
        state.Portfolios.push(payload);
    },
    setPortfolios(state, payload) {
        state.Portfolios = [];
        payload.forEach((ele) => {
            state.Portfolios.push(ele);
        })
        console.log('***** SetPortfolios - Mutation *****');
        console.log(state);
    },
    updatePortfolio(state, payload) {
        var found = false;

        state.Portfolios.forEach( (port) => {
            if (port.tradename === payload.tradename) {
                port.updated = payload.updated;
                port.ticker = payload.ticker;
                port.active = payload.active;
                port.hvol = payload.hvol;
                port.positions = payload.positions;
                found = true
            }
        })
        if (!found) {
            console.log('pushing a new portfolio to array in the updatePortfolio mutation');
            state.Portfolios.push(payload);
        } 
        return; 
    },
    removePortfolioByName(state,payload) {
		var index = state.Portfolios.findIndex((Portfolio) => {
            Portfolio.tradename === payload.tradename
        });
		if (index !== -1) {
			state.Portfolios.splice(index, 1);
		}        
    }
};