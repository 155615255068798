import Vue from 'vue'
import App from './App.vue'

import GoogleSignInButton from 'vue-google-signin-button-directive'
import router from './router'
import store from './store'
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';

Vue.config.productionTip = false
Vue.component('base-card',BaseCard)
Vue.component('base-button',BaseButton)
Vue.component('base-dialog',BaseDialog)
Vue.component('base-spinner',BaseSpinner)

new Vue({
  GoogleSignInButton,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
