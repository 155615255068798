import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      pnls: []
    };
  },
  mutations,
  actions,
  getters
};

// {
//   owner: null,
//   ticker: null,
//   opendate: null,
//   closedate:null,
//   status: null,
//   qtr: null,
//   amount: null,
//   capital:null,
//   margin:null,
//   tradename: null
// }