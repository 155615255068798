<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      For option quotes check out
      <a href="https://www.cboe.com" target="_blank" rel="noopener">CBOE</a>.<br>
      For historical IV data go to 
      <a href="https://www.optionstrategist.com/calculators/free-volatility-data" target="_blank" rel="noopener">Option Strategist</a><br>
      For earnings calendar visit
      <a href="https://marketchameleon.com/Calendar/Earnings" target="_blank" rel="noopener">Earnings</a>.<br>
    </p>
  </div>
</template>

<script>
export default {
  name: 'HomeComponent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-weight: bold;
  color: rgb(6, 141, 6);
  font-size: 50px;
}

a {
  color: #42b983;
}
</style>
