<template>
  <header>
    <nav>
      <h1>
        <router-link to="/">Home</router-link>
      </h1>
      <ul>
        <li>
          <router-link to="/login">Login</router-link>
        </li>
        <div v-if="isLoggedIn">
        <li>
          <router-link to="/hvol">Volatility</router-link>
          <router-link to="/prospect">Prospects</router-link>
          <router-link to="/portfolio">Portfolio</router-link>
          <router-link to="/pnl">P&L</router-link>
          <router-link to="/coe">COE</router-link>
          <router-link to="/pt/AMZN">PowerTrade</router-link>
        </li>
        </div>
        <li>
          <router-link to="/option">Option-Calculator</router-link>
        </li>
        <li>
          <router-link to="/spread">Spread-Calculator</router-link>
        </li>
        <li>
          <router-link to="/d2e">DaysToExp</router-link>
        </li>
        <li v-if="isLoggedIn">
          <router-link to="/logout">Logout</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/home');
    }
  }
}
</script>

<style scoped>
header {
  height: 100%; /* 100% Full-height */
  width: 15%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgb(9, 53, 36); 
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 40px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

header a,li,ul,nav {
  text-decoration: none;
  color: #d8f004;
  display: block;
  font-size: 20px;
  padding: 4px;
  border: 1px solid transparent;
  transition: 0.3s; 
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #f391e3;
}

h1 a {
  color: white;
  margin: 0;
  font-size: 40px;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

</style>