import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      Portfolios: []
    };
  },
  mutations,
  actions,
  getters
};

// {
//   owner: null,
//   ticker: null,
//   updated: null,
//   active: null,
//   hvol: null,
//   tradename: null,
//   positions: [],
// }