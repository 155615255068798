<template>
  <div>
    <base-card>
    <div class="container">
      <div class="row">
        <h3>Determine number of trading days to expiration</h3>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <form id="dateForm" @submit.prevent="submitForm">
            <div class="form-group">
              <label>Expiration date</label>
              <input type="date" class="form-control" id="eD" v-model="eD" />
            </div>
            <base-button>Run</base-button>
          </form>
        </div>
        <div class="col-sm-4">
          <div class="form-group">
            <label v-if="exDt" id="exDt" class="form-control" style="color: green; font-weight: bold">
              Number of days: {{ exDt }}
            </label>
          </div>
        </div>
      </div>
    </div>
        </base-card>
  </div>
</template>

<script>
import BaseButton from './ui/BaseButton.vue';
import BaseCard from './ui/BaseCard.vue';

export default {
  components: { BaseCard, BaseButton },
  data() {
    return {
      eD: "",
      exDt: null,
    };
  },
  methods: {
    submitForm() {
      this.exDt = this.dateCalculator(this.eD);
    },
    dateCalculator(ed) {
      console.log(ed);
      var numDays = 0;

      var curDate = new Date();
      var toDate = Date.parse(ed);
      console.log(curDate);
      while (curDate <= toDate) {
        console.log(curDate);
        var dayOfWeek = curDate.getDay();
        if (!(dayOfWeek == 6 || dayOfWeek == 0)) numDays++;
        curDate.setDate(curDate.getDate() + 1);
      }

      console.log("Returning Number of Days to Expiration- " + numDays);
      return numDays;
    },
  },
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
}

input[type='checkbox'] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  border: none;
}

input[type='checkbox']:focus {
  outline: #3d008d solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>

