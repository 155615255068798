<template>
  <div>
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>

    <div v-if="showImportDialog" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="importProspects">
          <h4>Please enter your JSON array here</h4>
          <textarea
            v-model="json"
            spellcheck="false"
            id="note"
            rows="30"
            cols="80"
          ></textarea>
          <br />
          <br />
          <base-button type="submit">Import</base-button>
          <base-button type="button" @click.native="closeImportDialog"
            >Close</base-button
          >
        </form>
      </div>
    </div>

    <div v-if="showConfirmDialog" class="json-popup">
      <div class="form-popup" id="popupForm">
        <form class="form-container" @submit.prevent="post2Portfolio">
          <div v-for="(port, i) in prospect.port" :key="i">
            <p>
              {{ port.sp }} | {{ port.cps }} | {{ port.xp }} |
              {{ port.up.toFixed(2) }}
            </p>
          </div>
          <br />
          <br />
          <p>Margin of Safety: {{ getMos * 100 }}</p>
          <p>Max $$ exposure: {{ getMaxExp }}</p>
          <br />
          <h4>Please enter the net credit expected</h4>
          <input v-model="netCredit" id="netCredit" class="T4" />
          <br />
          <br />

          <br />
          <br />
          <base-button type="submit">Post</base-button>
          <base-button type="button" @click.native="closeConfirmDialog"
            >Close</base-button
          >
        </form>
      </div>
    </div>
    <section>
      <base-card>
        <div v-if="showSpinner">
          <base-spinner></base-spinner>
        </div>
        <div class="controls">
          <base-button mode="outline" link to="/addprospect">Add</base-button>
          <base-button mode="outline" @click.native="openImportDialog"
            >Import</base-button
          >
        </div>
        <table v-if="hasProspects" class="table">
          <th>Quarter</th>
          <th>ER-Date</th>
          <th>B/A</th>
          <th>Ticker</th>
          <th>Name</th>
          <th>Status</th>
          <th></th>
          <th></th>
          <prospect-item
            @notdatoken="noTdaToken"
            @save2db="openConfirmDialog"
            @delprospect="deleteProspect"
            @notrade="noTrade"
            v-for="pros in Prospects"
            :key="pros.name"
            :qtr="pros.qtr"
            :erdate="pros.erdate"
            :ba="pros.ba"
            :ticker="pros.ticker"
            :status="pros.status"
            :name="pros.name"
          ></prospect-item>
        </table>
        <h3 v-if="!hasProspects">No Prospects found.</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import ProspectItem from "./ProspectItem.vue";
import myMixins from "../mixins/CreateOrder.js";

export default {
  components: {
    ProspectItem,
  },
  mixins: [myMixins],
  data() {
    return {
      netCredit: 0,
      isLoading: false,
      error: null,
      showConfirmDialogFlag: false,
      showImportDialogFlag: false,
      prospect: null,
      json: "",
      jsonArr: [],
    };
  },
  computed: {
    Prospects() {
      var prospectList = this.$store.getters["prospect/getAllProspects"];
      prospectList.sort((a, b) => {
        if (a.erdate == b.erdate) {
          if (a.ba == b.ba) {
            if (a.ticker < b.ticker) {
              return 1;
            } else {
              return -1;
            }
          }
          if (a.ba < b.ba) {
            return 1;
          } else {
            return -1;
          }
        }
        if (a.erdate < b.erdate) {
          return -1;
        } else {
          return +1;
        }
      });
      return prospectList;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    hasProspects() {
      return this.$store.getters["prospect/hasProspects"];
    },
    showSpinner() {
      return this.isLoading;
    },
    showConfirmDialog() {
      return this.showConfirmDialogFlag;
    },
    showImportDialog() {
      return this.showImportDialogFlag;
    },
    getMos() {
      var cs = 0;
      var ps = 0;
      this.prospect.port.forEach((ele) => {
        var factor = 1;
        if (Number(ele.xp) < 0) {
          factor = -1;
        }
        if (ele.cps === "C") {
          cs += factor * Number(ele.sp);
        }
        if (ele.cps === "P") {
          ps += factor * Number(ele.sp);
        }
      });
      var cmos = Math.abs(this.netCredit / cs);
      var pmos = Math.abs(this.netCredit / ps);
      cmos = Math.round(cmos * 100, 2) / 100;
      pmos = Math.round(pmos * 100, 2) / 100;
      return cmos < pmos ? cmos : pmos;
    },
    getMaxExp() {
      var cs = 0;
      var ps = 0;
      this.prospect.port.forEach((ele) => {
        var factor = 1;
        if (Number(ele.xp) < 0) {
          factor = -1;
        }
        if (ele.cps === "C") {
          cs += factor * Number(ele.sp);
        }
        if (ele.cps === "P") {
          ps += factor * Number(ele.sp);
        }
      });
      var maxExp = 0;
      var nc = Math.abs(this.netCredit);
      maxExp = cs - nc;
      if (ps - nc > maxExp) {
        maxExp = ps - nc;
      }
      maxExp = Math.round(maxExp * 100, 2) / 100;
      return maxExp;
    },
  },
  created() {
    this.loadProspects();
  },
  methods: {
    async loadProspects() {
      this.isLoading = true;
      if (!this.$store.getters["prospect/hasProspects"]) {
        try {
          await this.$store.dispatch("prospect/setProspects", {
            qtr: this.$store.getters["misc/getQtr"],
          });
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      }
      this.isLoading = false;
    },
    noTdaToken(event) {
      console.log("got to event handler in prospect list");
      this.error = event.message;
    },
    handleError() {
      this.error = null;
    },
    openImportDialog() {
      this.showImportDialogFlag = true;
    },
    closeImportDialog() {
      this.showImportDialogFlag = false;
    },
    openConfirmDialog(obj) {
      this.prospect = obj;
      this.netCredit = 0;
      obj.port.forEach((ele) => {
        this.netCredit += Number(ele.xp) * Number(ele.up);
      });
      this.netCredit /= 100;
      this.showConfirmDialogFlag = true;
    },
    closeConfirmDialog() {
      this.showConfirmDialogFlag = false;
    },
    async importProspects() {
      this.isLoading = true;
      var tdatoken = this.$store.getters.tdatoken;
      var qtr = this.$store.getters["misc/getQtr"];

      if (tdatoken !== null) {
        this.jsonArr = JSON.parse(this.json);
        await this.evalAndPostProspect(this.jsonArr, 0, tdatoken, qtr);
      }
      this.isLoading = false;
    },
    async evalAndPostProspect(jsonArr, idx, tdatoken, qtr) {
      var ele = jsonArr[idx];
      var tkr = ele.ticker.trim().toUpperCase();

      const response = await fetch(
//        `https://api.tdameritrade.com/v1/instruments?symbol=${tkr}&projection=fundamental`,
        `https://api.schwabapi.com/marketdata/v1/instruments?symbol=${tkr}&projection=fundamental`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + tdatoken
          },
        }
      );

      const jsonResp = await response.json();
      console.log("Response from TDA:", jsonResp);
      if (JSON.stringify(jsonResp) !== "{}") {
        console.log(
          "Market cap for:",
          tkr,
          ":",
          jsonResp[tkr].fundamental.marketCap
        );

        if (jsonResp[tkr].fundamental.marketCap > 5000) {
          var ba = "";
          if (ele.ba === "bmo") {
            ba = "B";
          } else if (ele.ba === "amc") {
            ba = "A";
          }

          if (ba == "A" || ba == "B") {
            const formData = {
              qtr: qtr,
              ticker: tkr,
              erdate: ele.erdate,
              ba: ba,
              name: tkr + "-er-" + ele.erdate,
              status: "Open",
            };
            console.log(formData);
            await this.$store.dispatch("prospect/registerProspect", formData);
          } else {
            console.log("ER release time unknown for-", tkr);
          }
        }
      }

      idx++;
      if (idx < jsonArr.length) {
        await this.evalAndPostProspect(jsonArr, idx, tdatoken, qtr);
      } else {
        return;
      }
    },
    async post2Portfolio() {
      this.showConfirmDialogFlag = false;
      this.isLoading = true;
      await this.$store.dispatch("portfolio/registerPortfolio", this.prospect);
      console.log("Done creating the trade and saving in portfolio table");

      var token = this.$store.getters.tdatoken;
      var tdacct = this.$store.getters.tdacct;
      if (tdacct !== null) {
        await this.createOrder(token, tdacct, this.prospect, this.netCredit);
        console.log("Created order on TDA - Yayyy!!");
      }

      var prospectList = this.$store.getters["prospect/getAllProspects"];
      var prospectToUpdate = null;
      console.log("this prospect:", this.prospect);
      prospectToUpdate = prospectList.find(
        (ele) => ele.name === this.prospect.tradename
      );
      prospectToUpdate.status = "Traded";
      await this.$store.dispatch("prospect/registerProspect", prospectToUpdate);

      // var watchlist = {
      //   name: "ii888",
      //   watchlistId: "1600544266",
      //   watchlistItems: [
      //     {
      //       instrument: {
      //         symbol: prospectToUpdate.ticker,
      //         assetType: "EQUITY",
      //       },
      //     },
      //   ],
      // };

      // const response = await fetch(
      //   `https://api.tdameritrade.com/v1/accounts/${tdacct}/watchlists/1600544266`,
      //   {
      //     method: "PATCH",
      //     headers: {
      //       Authorization: token,
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(watchlist),
      //   }
      // );
      // console.log("Response from WL update", response);
      this.isLoading = false;
    },
    async deleteProspect(obj) {
      this.isLoading = true;
      await this.$store.dispatch("prospect/removeProspects", obj);
      this.loadProspects();
      this.isLoading = false;
    },
    noTrade(event) {
      this.error = event.message;
    },
  },
};
</script>

<style scoped>
.json-popup {
  position: relative;
  text-align: center;
  width: 50%;
  opacity: 1;
}
.form-popup {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-45%, 5%);
  border: 2px solid rgb(100, 24, 24);
  z-index: 9;
  opacity: 1;
  padding: 20px 20px;
  background-color: rgb(233, 241, 234);
}
.form-container {
  opacity: 1;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}

table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  border: 16px ridge(5, 10, 1);
}

th {
  vertical-align:middle;
  border: 1px solid;
  height: 36px;
  font-size: 12px;  
  padding: 0px 0px;
  background-color: rgba(37, 185, 136, 0.5);
}
</style>