var myOptionCalcMixins = {
    methods: {
        optionCalculator(mP, sP, rate, tm, vol) {
            console.log(mP + "-" + sP + "-" + rate + "-" + tm + "-" + vol);
            var d1 = 0.0;
            var d2 = 0.0;
            var f1, f2, f3;
            var C;
            var P; 
            var delta;
            var gamma;
            var vega;
            var theta;

            if (tm > 0) {
                rate = rate / 100.0;
                vol = vol / 100.0;
                tm = tm / 253;
    
                f1 = Math.pow(vol, 2.0);
                f2 = rate + f1 / 2;
                f2 = f2 * tm;
                f3 = Math.pow(tm, 0.5);
    
                d1 = Math.log(mP / sP) + f2;
                d1 = d1 / (vol * f3);
                d2 = d1 - vol * f3;
    
                delta = this.getNormal(d1);
                var nd1 = 0.0;
                nd1 = this.getNormalDash(d1);
                gamma = nd1 / (mP * vol * f3);
                vega = (mP * f3 * nd1) / 100;
                theta =
                    (rate * sP * Math.exp(-1 * rate * tm) * this.getNormal(d1 - vol * f3) +
                        (mP * vol * nd1) / (2 * f3)) /
                    253;
    
                C =
                    mP * this.getNormal(d1) -
                    sP * Math.exp(-1 * rate * tm) * this.getNormal(d2);
                P = sP * Math.exp(-1 * rate * tm) - mP + C;
                console.log("Call-" + C);
                console.log("Put-" + P);
                C = this.myRound(C);
                P = this.myRound(P);
                delta = this.myRound(delta);
                gamma = this.myRound(gamma);
                vega = this.myRound(vega);
                theta = this.myRound(theta);
            } else {
                delta = 0;
                gamma = 0;
                theta = 0;
                vega = 0;
                C = 0;
                P = 0;
                if (mP > sP) {
                    C = mP - sP;
                }
                if (mP < sP) {
                    P = sP - mP;
                }
            }

            var opts = new Array(C, P, delta, gamma, vega, theta);

            console.log("Returning - " + opts);
            return opts;
        },
        myRound(x) {
            x = Math.round(x * 100, 2) / 100;
            return x;
        },
        getNormal(z) {
            var c1 = 2.506628; // sq-root(2 * pi)
            var c2 = 0.3193815;
            var c3 = -0.3565638;
            var c4 = 1.7814779;
            var c5 = -1.821256;
            var c6 = 1.3302744;
            var w = 0;
            var y = 0;
            var N = 0;

            if (z >= 0) w = 1;
            else w = -1;

            y = 1 / (1 + 0.231649 * w * z);
            N =
                0.5 +
                w *
                (0.5 -
                    (Math.exp((-1 * z * z) / 2) / c1) *
                    (y * (c2 + y * (c3 + y * (c4 + y * (c5 + y * c6))))));
            return N;
        },
        getNormalDash(z) {
            var Nd = 0.0;
            var c1 = 2.506628; // sq-root(2 * pi)
            Nd = Math.exp((-1 * z * z) / 2) / c1;
            return Nd;
        },
        dateCalculator(ed) {
            console.log(ed);
            var numDays = 0;

            var curDate = new Date();
            var toDate = Date.parse(ed);
            console.log(curDate);
            while (curDate <= toDate) {
                var dayOfWeek = curDate.getDay();
                if (!(dayOfWeek == 6 || dayOfWeek == 0)) numDays++;
                curDate.setDate(curDate.getDate() + 1);
            }

            console.log("Returning Number of Days to Expiration- " + numDays);
            return numDays;
        }
    }
}

export default myOptionCalcMixins;