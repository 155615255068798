import { render, staticRenderFns } from "./PnlItem.vue?vue&type=template&id=19604850&scoped=true&"
import script from "./PnlItem.vue?vue&type=script&lang=js&"
export * from "./PnlItem.vue?vue&type=script&lang=js&"
import style0 from "./PnlItem.vue?vue&type=style&index=0&id=19604850&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19604850",
  null
  
)

export default component.exports